import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import OutsideClickHandler from "react-outside-click-handler";
import { FaAngleRight } from "react-icons/fa";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { isEmpty, startCase } from "lodash";
import obj from "../Utils/helpers";
import { FaAngleLeft } from "react-icons/fa";
const MyBetsNew = ({ setShow }) => {
  const [singleBet, SetSingleBet] = useState(false);
  const [data, setData] = useState([]);
  const handleSingleBet = (eventName, type, list) => {
    SetSingleBet({
      eventName: eventName,
      type: type,
      list: list,
    });
  };

  const getCurrentBets = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.mobileCurrentBets
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };

  useEffect(() => {
    getCurrentBets();
  }, []);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShow(false);
      }}
    >
      <div className="market-depth-modal">
        <div className="market-title">
          {!isEmpty(singleBet?.eventName) &&
          !isEmpty(singleBet?.type) &&
          singleBet?.list?.length > 0 ? (
            <>
              <button
                onClick={() => SetSingleBet({})}
                className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
              >
                <FaAngleLeft className="fs-3" />
              </button>
              <div>
                <span className="">{singleBet?.eventName}</span>
                <br />
                <span className="master-pass">{singleBet?.type}</span>
              </div>
            </>
          ) : (
            <h4>My Bets</h4>
          )}
          <Button
            onClick={() => setShow(false)}
            className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
          >
            <RxCross2 />
          </Button>
        </div>
        {data?.length > 0 ? (
          <div className="market-depth-body">
            {!isEmpty(singleBet?.eventName) &&
            !isEmpty(singleBet?.type) &&
            singleBet?.list?.length > 0 ? (
              <div>
                <h5 className="text-center bg-darklight mb-0 py-2 fs-5">
                  <div className="match-border-sec">
                    Matched{" "}
                    <span className="bg-dark text-white p-2 d-inline-block py-1 fs-6 rounded">
                      {singleBet?.list?.length}
                    </span>
                  </div>
                </h5>
                <div className="current-bets-table p-2 border-0 single-match-body">
                  {singleBet?.list?.map((item, index) => {
                    return (
                      <Table
                        key={index}
                        bordered
                        className="rounded overflow-hidden bg-white mb-3 odds-table"
                      >
                        <tbody>
                          <tr className="lay-back">
                            <td colSpan="3">
                              {" "}
                              {singleBet?.type == "Lottery" ? (
                                <span className={"bet-name back"}>
                                  {startCase(item?.lotteryType)} Digit
                                </span>
                              ) : (
                                <span
                                  className={`bet-name ${
                                    item?.type == "No"
                                      ? "lay"
                                      : item?.type == "Yes"
                                      ? "back"
                                      : item?.betType
                                  }`}
                                >
                                  {startCase(item?.betType || item?.type)}
                                </span>
                              )}
                              {item?.teamName ||
                                item?.fancyName ||
                                obj.dateFormat(item?.timeInserted)}
                            </td>
                          </tr>
                          <tr>
                            {singleBet?.type === "Lottery" && (
                              <td>
                                Selection
                                <strong className="d-block">
                                  {" "}
                                  {item?.selectionId}
                                </strong>
                              </td>
                            )}
                            <td>
                              Odds.{" "}
                              <strong className="d-block">
                                {" "}
                                {!isEmpty(item?.betType)
                                  ? item?.bhav
                                  : `${item?.betRun}/${item?.bhav}`}
                              </strong>
                            </td>
                            <td>
                              Stake (INR){" "}
                              <strong className="d-block">
                                {" "}
                                {item?.amount}
                              </strong>
                            </td>
                            {singleBet?.type !== "Lottery" && (
                              <td>
                                Profit (INR)
                                <strong className="d-block">
                                  {" "}
                                  {item?.profitAmount}
                                </strong>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td className="" colSpan="3">
                              <div className="d-flex justify-content-between">
                                Ref:{" "}
                                {item?.matchBetId
                                  ? item?.matchBetId
                                  : item?.sessionBetId || "--"}
                                {/* <span>
                                  {obj.dateFormat(item?.timeInserted)}
                                </span> */}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    );
                  })}
                </div>
              </div>
            ) : (
              <ol className="p-0 m-0">
                {data?.length > 0
                  ? data?.map((item, index) => {
                      return (
                        <>
                          {item?.betList?.length > 0 && (
                            <li
                              className="d-flex align-items-center justify-content-between"
                              onClick={() =>
                                handleSingleBet(
                                  item?.eventName,
                                  "BetFair",
                                  item?.betList
                                )
                              }
                            >
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.betList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">BetFair</span>
                              </div>

                              <Button className="right-angel px-2">
                                <FaAngleRight />
                              </Button>
                            </li>
                          )}
                          {item?.bookmakerList?.length > 0 && (
                            <li
                              className="d-flex align-items-center justify-content-between"
                              onClick={() =>
                                handleSingleBet(
                                  item?.eventName,
                                  "Bookmaker",
                                  item?.bookmakerList
                                )
                              }
                            >
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.bookmakerList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">Bookmaker</span>
                              </div>

                              <Button className="right-angel px-2">
                                <FaAngleRight />
                              </Button>
                            </li>
                          )}
                          {item?.sessionBetList?.length > 0 && (
                            <li
                              className="d-flex align-items-center justify-content-between"
                              onClick={() =>
                                handleSingleBet(
                                  item?.eventName,
                                  "Fancy",
                                  item?.sessionBetList
                                )
                              }
                            >
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.sessionBetList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">Fancy</span>
                              </div>

                              <Button className="right-angel px-2">
                                <FaAngleRight />
                              </Button>
                            </li>
                          )}
                          {item?.sportBookBetList?.length > 0 && (
                            <li
                              className="d-flex align-items-center justify-content-between"
                              onClick={() =>
                                handleSingleBet(
                                  item?.eventName,
                                  "SportsBook",
                                  item?.sportBookBetList
                                )
                              }
                            >
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.sportBookBetList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">Sports Book</span>
                              </div>

                              <Button className="right-angel px-2">
                                <FaAngleRight />
                              </Button>
                            </li>
                          )}
                          {item?.tieBetList?.length > 0 && (
                            <li
                              className="d-flex align-items-center justify-content-between"
                              onClick={() =>
                                handleSingleBet(
                                  item?.eventName,
                                  "Tie",
                                  item?.tieBetList
                                )
                              }
                            >
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.tieBetList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">Tie Bet</span>
                              </div>

                              <Button className="right-angel px-2">
                                <FaAngleRight />
                              </Button>
                            </li>
                          )}
                          {item?.tossBetList?.length > 0 && (
                            <li
                              className="d-flex align-items-center justify-content-between"
                              onClick={() =>
                                handleSingleBet(
                                  item?.eventName,
                                  "Toss",
                                  item?.tossBetList
                                )
                              }
                            >
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.tossBetList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">Toss Bet</span>
                              </div>

                              <Button className="right-angel px-2">
                                <FaAngleRight />
                              </Button>
                            </li>
                          )}
                          {item?.lotteryBetList?.length > 0 && (
                            <li
                              className="d-flex align-items-center justify-content-between"
                              onClick={() =>
                                handleSingleBet(
                                  item?.eventName,
                                  "Lottery",
                                  item?.lotteryBetList
                                )
                              }
                            >
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.lotteryBetList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">Lottery</span>
                              </div>

                              <Button className="right-angel px-2">
                                <FaAngleRight />
                              </Button>
                            </li>
                          )}
                        </>
                      );
                    })
                  : ""}
              </ol>
            )}
          </div>
        ) : (
          <div
            className="market-depth-body d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            {" "}
            No Record Found
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default MyBetsNew;
