import React, { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
const BackLayOuter = ({ odds_lay, odds_back, item }) => {
  let backClass = `back ${
    Number(
      Number(
        document
          .getElementById("back_odds_" + item?.SelectionId)
          ?.title?.split("-")[0]
          ?.trim()
      )
    ) !== Number(odds_back[0]?.rt) ||
    Number(
      Number(
        document
          .getElementById("back_odds_" + item?.SelectionId)
          ?.title?.split("-")[1]
          ?.trim()
      )
    ) !== Number(odds_back[0]?.bv)
      ? "spark-back"
      : ""
  }`;
  let layClass = `lay ${
    Number(
      Number(
        document
          .getElementById("lay_odds_" + item?.SelectionId)
          ?.title?.split("-")[0]
          ?.trim()
      )
    ) !== Number(odds_lay[0]?.rt) ||
    Number(
      Number(
        document
          .getElementById("lay_odds_" + item?.SelectionId)
          ?.title?.split("-")[1]
          ?.trim()
      )
    ) !== Number(odds_lay[0]?.bv)
      ? "spark-lay"
      : ""
  }`;
  
  return (
    <>
      <div className={`lay-back-table lay-back-table-new`}>
        <div
          title={`${Number(odds_back[0]?.rt) > 0 ? odds_back[0]?.rt : ""}-${
            Number(odds_back[0]?.bv) > 0 ? odds_back[0]?.bv : ""
          }`}
          id={"back_odds_" + item?.SelectionId}
          className={backClass}
        >
          <span>
            <strong>{odds_back[0]?.rt}</strong>
          </span>
        </div>
        <div
          title={`${Number(odds_lay[0]?.rt) > 0 ? odds_lay[0]?.rt : ""}-${
            Number(odds_lay[0]?.bv) > 0 ? odds_lay[0]?.bv : ""
          }`}
          id={"lay_odds_" + item?.SelectionId}
          className={layClass}
        >
          <span>
            <strong>{odds_lay[0]?.rt}</strong>
          </span>
        </div>
      </div>
    </>
  );
};

export default BackLayOuter;
