import React, { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import "../src/assets/css/style.css";
import "../src/assets/css/media.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./pages/Home";
import Login from "./Auth/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import BalanceOverview from "./pages/BalanceOverview";
import Logs from "./pages/Logs";
import Profile from "./pages/Profile";
import ChangePassword from "./Auth/ChangePassword";
import P2ptransfer from "./pages/P2ptransfer";
import Settings from "./pages/Settings";
import BetsHistory from "./pages/BetsHistory";
import Ptransferlog from "./pages/Ptransferlog";
import ProfitLoss from "./pages/ProfitLoss";
import Casino from "./pages/casino/Casino";
import Sports from "./pages/Sports";
import Leagues from "./pages/Leagues";
import ParlayFullMarket from "./pages/ParlayFullMarket";
import UplineWhatsapp from "./pages/UplineWhatsapp";
import { AuthProvider } from "./context/AuthContext";
import { BetSlipProvider } from "./context/BetSlipContext";
import CurrentBets from "./pages/CurrentBets/CurrentBets";
import FullMarket from "./pages/MatchDetail/FullMarket";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import AccountStatement from "./pages/AccountStatement";
import Registration from "./Auth2/Registration";
import "./App.css";
import MyAccount from "./pages/MyAccount";
import Deposit from "./pages/Deposit";
import WithDraw from "./pages/WithDraw";
import DepositHistory from "./pages/DepositHistory";
import WithdrawHistory from "./pages/WithdrawHistory";
import HomeNew from "./pages/HomeNew";
import ProtectedRoute from "./components/ProctectedRoute";
import ForgotPassword from "./Auth/ForgotPassword";
import Index from "./CustomerSupport.js/Index";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <AuthProvider>
          <BetSlipProvider>
            <Outlet />
          </BetSlipProvider>
        </AuthProvider>
      }>
      <Route path="/" element={<HomeNew />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/myAccount"
        element={
          <ProtectedRoute>
            <MyAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path="/balance-overview"
        element={
          <ProtectedRoute>
            <BalanceOverview />
          </ProtectedRoute>
        }
      />{" "}
      <Route
        path="/customer-support"
        element={
          // <ProtectedRoute>
            <Index />
          // </ProtectedRoute>
        }
      />
      <Route
        path="/current-bets"
        element={
          <ProtectedRoute>
            <CurrentBets />
          </ProtectedRoute>
        }
      />
      <Route
        path="/deposit"
        element={
          <ProtectedRoute>
            <Deposit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/withdraw"
        element={
          <ProtectedRoute>
            <WithDraw />
          </ProtectedRoute>
        }
      />
      <Route
        path="/active-log"
        element={
          <ProtectedRoute>
            <Logs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route
        path="/p2p-transfer"
        element={
          <ProtectedRoute>
            <P2ptransfer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/setting"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bets-history"
        element={
          <ProtectedRoute>
            <BetsHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/p2p-transfer-log"
        element={
          <ProtectedRoute>
            <Ptransferlog />
          </ProtectedRoute>
        }
      />
      <Route
        path="/account-statement"
        element={
          <ProtectedRoute>
            <AccountStatement />
          </ProtectedRoute>
        }
      />
      <Route path="/register" element={<Registration />} />
      <Route
        path="/profit-and-loss"
        element={
          <ProtectedRoute>
            <ProfitLoss />
          </ProtectedRoute>
        }
      />
      <Route
        path="/casino"
        element={
          <ProtectedRoute>
            <Casino />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sports"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/leagues"
        element={
          <ProtectedRoute>
            <Leagues />
          </ProtectedRoute>
        }
      />
      <Route
        path="/full-market"
        element={
          <ProtectedRoute>
            <FullMarket />
          </ProtectedRoute>
        }
      />
      <Route
        path="/full-market/:marketId/:eventId"
        element={
          <ProtectedRoute>
            <FullMarket />
          </ProtectedRoute>
        }
      />
      <Route
        path="/parlay-full-market"
        element={
          <ProtectedRoute>
            <ParlayFullMarket />
          </ProtectedRoute>
        }
      />
      <Route
        path="/upline-whatsapp-number"
        element={
          <ProtectedRoute>
            <UplineWhatsapp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/deposit-history"
        element={
          <ProtectedRoute>
            <DepositHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/withdraw-history"
        element={
          <ProtectedRoute>
            <WithdrawHistory />
          </ProtectedRoute>
        }
      />
    </Route>
  )
);

const App = () => {

  useEffect(() => {
    // Get the domain name
    const domainName = window.location.hostname;
    // Dynamically set the title and favicon based on the domain
    let newTitle = "WifiExch";
    let newFavicon = "%PUBLIC_URL%/favicon.ico"; // Default favicon path

    if (domainName.includes("aaexch.live") || domainName.includes("www.aaexch.live")) {
      newTitle = "AaExch";
      newFavicon = "%PUBLIC_URL%/ax.ico"; // Path to AaExch favicon
    } else if (domainName.includes("anybett.live") || domainName.includes("www.anybett.live")) {
      newTitle = "Anybett";
      newFavicon = "%PUBLIC_URL%/ax.ico"; // Path for Anybett favicon
    }

    // Update the document title
    document.title = newTitle;

    // Update the favicon
    const faviconElement = document.getElementById("favicon");
    if (faviconElement) {
      faviconElement.href = newFavicon;
    }
  }, []);

  useEffect(() => {
    if (isBrowser) {
      document.body.classList.add("background-body");
    } else {
      document.body.classList.remove("background-body");
    }
  }, [isMobile, isBrowser]);

  return <RouterProvider router={router} />;
};

export default App;
