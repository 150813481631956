import React, { useContext } from "react";
import GameCard from "../GameCard";
import GameSidebar from "../GameSidebar";
import {
  gamesLiveArray,
  tableSelectionArray,
} from "../../constraints/constants";
import { Button } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const TableGames = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  return (
     <div className={user ? "games-inner extra-space": "games-inner"}>
      {/* <GameSidebar sideDataType="tableData" /> */}
      <div className="games-wrapper">
        <div className="games-card-inner">
          <div className="row">
            {gamesLiveArray
              ?.filter((res) => {
                return res?.type == "TABLE";
              })
              ?.slice(0, 20)
              ?.map((res, index) => {
                return <GameCard key={index} res={res} />;
              })}
          </div>
        </div>
        {/* <div className="w-100 d-flex justify-content-center align-items-center">
          <Button
            onClick={() => {
              navigate(`/casino?category=table`);
            }}
            style={{ background: "black", border: "none" }}
          >
            View More <IoIosArrowForward />
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default TableGames;
