import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import DepositIcon from "../assets/imagesNew/icon-deposit.png";
import iconWithdrawal from "../assets/imagesNew/icon-withdrawal.png";
import iconBetReacord from "../assets/imagesNew/icon-bet-records.png";
import iconTurnover from "../assets/imagesNew/icon-turnover.png";

import iconRecords from "../assets/imagesNew/icon-records.png";
import iconWhatsapp from "../assets/imagesNew/icon-whatsapp.png";
import iconEmail from "../assets/imagesNew/icon-email.png";
import iconFb from "../assets/imagesNew/icon-facebook-messenger.png";
import iconLogout from "../assets/imagesNew/header-logout-icon.svg";
import iconProfile from "../assets/imagesNew/icon-profile.png";
import iconresetpasswords from "../assets/imagesNew/icon-resetpasswords.png";
import userImage from "../assets/imagesNew/userImage.png";
import rightArrow from "../assets/images/right-arrow.png";
import { HiOutlineRefresh } from "react-icons/hi";
import { IoIosArrowBack } from "react-icons/io";
import LayoutComponent from "../components/shared/Layout";

const MyAccount = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const {
    user,
    handleShowSidebar,
    showSidebar,
    amounutRefresh,
    refreshAmountLoader,
    userCoins,
    loginUser,
    logoutUser,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <LayoutComponent visibilityType={true}>
    <div class="main dw-p deposit-box page-content-box w-100 bg-gradual-black">
      <div class="member-menu">
        <div class="member-menu-content bonuswallet">
          <div
            className="d-flex align-items-center w-100"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="left-arrow-icon"
              style={{
                background: "white",
                borderRadius: "50%",
              }}
              onClick={() => navigate("/")}
            >
              <IoIosArrowBack
                fontWeight={700}
                size={30}
                className="fs-1 p-1 cursor-pointer"
              />
            </div>{" "}
            <h2 style={{ padding: "15px 0" }}>My Account</h2>
          </div>

          <div class="member-menu-box balance-box">
            <div class="balance balance-row">
              <div class="text">
                {" "}
                Main Wallet{" "}
                <div onClick={amounutRefresh}>
                  <HiOutlineRefresh
                    size={23}
                    color="#c0a024"
                    style={{ paddingLeft: "5px" }}
                    className={refreshAmountLoader ? "refresh-icon" : ""}
                  />
                </div>
                <div class="icon eyes"></div>
              </div>
              {refreshAmountLoader ? (
                <div class="loader-outer">
                  <span
                    class="loader-inner-icon"
                    style={{ animationDelay: "0s" }}
                  ></span>
                  <span
                    class="loader-inner-icon"
                    style={{ animationDelay: ".1s" }}
                  ></span>
                  <span
                    class="loader-inner-icon"
                    style={{ animationDelay: ".2s" }}
                  ></span>
                  <span
                    class="loader-inner-icon"
                    style={{ animationDelay: ".3s" }}
                  ></span>
                  <span
                    class="loader-inner-icon"
                    style={{ animationDelay: ".4s" }}
                  ></span>
                  <span
                    class="loader-inner-icon"
                    style={{ animationDelay: ".5s" }}
                  ></span>
                  <span
                    class="loader-inner-icon"
                    style={{ animationDelay: ".6s" }}
                  ></span>
                  <span
                    class="loader-inner-icon"
                    style={{ animationDelay: ".7s" }}
                  ></span>
                </div>
              ) : (
                <span class="amount totalBalanceWallet">
                  <i>
                    <i id="locale-util-dicrective-0">
                      {userCoins?.balance?.toFixed() || 0.0}
                    </i>
                  </i>
                </span>
              )}

              <span id="balanceHeader">-</span>
            </div>
          </div>
          <div class="member-menu-box member-list">
            <div class="title">
              <h2>
                <span>Funds</span>
              </h2>
            </div>
            <ul class="align-center">
              <li class="deposit" onClick={() => navigate("/deposit")}>
                {" "}
                <a>
                  <span class="item-icon">
                    <img src={DepositIcon} />
                  </span>
                  <p>Deposit</p>
                </a>
              </li>
              <li class="withdrawal" onClick={() => navigate("/withdraw")}>
                {" "}
                <a>
                  <span class="item-icon">
                    <img src={iconWithdrawal} />
                  </span>
                  <p>Withdrawal</p>
                </a>
              </li>
            </ul>
          </div>
          <div class="member-menu-box member-list">
            <div class="title">
              <h2>
                <span>History</span>
              </h2>
            </div>
            <ul class="align-center">
              <li class="total" onClick={() => navigate("/bets-history")}>
                <a>
                  <span class="item-icon">
                    <img src={iconBetReacord} />
                  </span>
                  <p>Bets History</p>
                </a>
              </li>
              <li class="water" onClick={() => navigate("/profit-and-loss")}>
                <a>
                  <span class="item-icon">
                    <img src={iconTurnover} />
                  </span>{" "}
                  <p>Profit & Loss</p>
                </a>
              </li>
              <li
                class="history"
                onClick={() => navigate("/account-statement")}
              >
                <a>
                  <span class="item-icon">
                    <img src={iconRecords} />
                  </span>
                  <p>Account Statement</p>
                </a>
              </li>
            </ul>
          </div>
          {/* <div class="member-menu-box member-list">
            <div class="title">
              <h2>
                <span>Profile</span>
              </h2>
            </div>
            <ul class="align-center">
              <li class="account" onClick={() => navigate("/my-profile")}>
                <a>
                  <span class="item-icon">
                    <img src={iconProfile} />
                  </span>
                  <p>Personal Info</p>
                </a>
              </li>
              <li class="resetpasswords">
                <a onClick={() => navigate("/reset-password")}>
                  {" "}
                  <span class="item-icon">
                    <img src={iconresetpasswords} />
                  </span>
                  <p>Reset password</p>
                </a>
              </li>
            </ul>
          </div> */}
          {/* <div class="member-menu-box member-list csicons">
            <div class="title">
              <h2>
                <span>Contact Us</span>
              </h2>
            </div>
            <ul class="align-center">
              <li class="whatsapp">
                <a href="#">
                  <span class="item-icon ">
                    <img src={iconWhatsapp} />
                  </span>
                  <p class="">Whatsapp</p>
                </a>
              </li>
              <li class="email ">
                {" "}
                <a>
                  <span class="item-icon ">
                    <img src={iconEmail} />
                  </span>
                  <p class="">Email </p>
                </a>
              </li>
              <li class="facebook-messenger ">
                <a href="#">
                  <span class="item-icon ">
                    <img src={iconFb} />
                  </span>
                  <p class="">Facebook</p>
                </a>
              </li>
            </ul>
          </div> */}
          <div class="member-menu-logout">
            <a>
              <span class="item-icon me-2">
                <img src={iconLogout} />
              </span>
              <div class="text" onClick={() => logoutUser()}>
                <p>Log out</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    </LayoutComponent>
  );
};

export default MyAccount;
