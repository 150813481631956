import React, { Fragment, useContext, useEffect, useState } from "react";
import { bottomNavigation } from "../constraints/constants";
import { Link, useLocation } from "react-router-dom";
import MyBets from "./MyBets";
import BetSlipContext from "../context/BetSlipContext";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import MyBetsNew from "./MyBetsNew";
const BottomNavigation = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { user, setKeyTime, setGameTab, gameTab, setActive } =
    useContext(AuthContext);
  const { showBet, setShowBet } = useContext(BetSlipContext);

  const check = (item, gameTab) => {
    if (item?.id == 6) {
      if (
        location?.pathname == item?.link &&
        (gameTab == "cricket" || gameTab == "tennis" || gameTab == "soccer")
      ) {
        return "active";
      } else {
        return "";
      }
    } else if (item?.id == 3) {
      if (location?.pathname == item?.link && gameTab == "all") {
        return "active";
      } else {
        return "";
      }
    } else {
      if (location?.pathname == item?.link) {
        return "active";
      } else {
        return "";
      }
    }
  };
  const [showMyBet2, setShowMyBet2] = useState(false);
  const handleShowBet2 = () => {
    setShowMyBet2(!showMyBet2);
  };

  return (
    <>
      <div className="bottom-navigation">
        <ul className="p-sm-3 p-2 m-0 py-sm-2">
          {bottomNavigation?.map((item, index) => {
            return (
              <Fragment key={index}>
                <li
                  className={check(item, gameTab)}
                  onClick={() => {
                    if (item?.id == 5) {
                      if (!isEmpty(user)) {
                        setShowBet(true);
                      } else {
                        navigate("/login");
                      }
                    } else if (item?.id == 3) {
                      setActive("InPlay");
                      setGameTab("all");
                      navigate(item?.link);
                    } else {
                      if (item?.id == 6) {
                        setGameTab("cricket");
                        setActive("Cricket");
                      } else if (item?.menu == "Home") {
                        setKeyTime("in_play");
                        setGameTab("all");
                      }
                      navigate(item?.link);
                    }
                  }}
                >
                  <div className="position-relative">
                    <img src={item?.icon} alt="" />
                  </div>
                  <span style={{ fontSize: ".7857142857142857rem" }}>
                    {item?.menu}
                  </span>
                </li>
              </Fragment>
            );
          })}
        </ul>
      </div>
      {showBet && (
        <MyBets setShowBet={setShowBet} handleShowBet2={handleShowBet2} />
      )}{" "}
      {showMyBet2 && <MyBetsNew setShow={handleShowBet2} />}
    </>
  );
};

export default BottomNavigation;
