import React, { useContext } from "react";
import AuthProvider from "../context/AuthContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
const GameCard = ({ item, cartType, res }) => {
  let { launchEGTCasino, user, launchCasino } = useContext(AuthProvider);
  const navigate = useNavigate();
  return (
    <div
      className="game-card position-relative"
      onClick={() => {
        if (!isEmpty(user)) {
          if (!isEmpty(res?.gameid)) {
            launchEGTCasino({
              platForm: res?.platForm,
              gameType: res?.gameType,
              casinoType: res?.casinoType,
              gameid: res?.gameid,
            });
          } else {
            if (
              res?.platForm !== "" &&
              res?.gameType !== "" &&
              res?.casinoType !== ""
            ) {
              launchCasino({
                platForm: res?.platForm,
                gameType: res?.gameType,
                casinoType: res?.casinoType,
              });
            }
          }
        } else {
          navigate("/login");
        }
      }}
    >
      {cartType && (
        <div className="table-data">
          <span className="">Table1</span>
        </div>
      )}
      <img src={res?.img} alt="" />
    </div>
  );
};

export default GameCard;
