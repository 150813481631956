import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import SidebarLayout from "../components/shared/SidebarLayout";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { IoIosArrowForward } from "react-icons/io";
const BalanceOverview = () => {
  const { userCoins, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  const handel = () => {
    setFilter({
      ...filter,
      page: filter?.page + 1,
    });
    getData(
      {
        ...filter,
        page: filter?.page + 1,
      },
      "add"
    );
  };
  const getData = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.transactionLogs, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results?.data]);
        } else {
          setListing(data?.results?.data);
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [user]);

  return (
    <SidebarLayout heading={"Balance Overview"}>
     <Container className="p-0">
     <div className="pt-3 inner-sidebar-content px-2">
        <div className="balance-sec">
          <h5>Your Balances</h5>
          <h6 className="fs-4">
            {" "}
            <span>INR</span> {userCoins?.balance?.toFixed()}
          </h6>
        </div>

        {listing?.length > 0
          ? listing?.map((res) => {
              return (
                <div className="balance-deposit-sec mt-3">
                  <Table bordered className="rounded overflow-hidden bg-white">
                    <thead>
                      <tr>
                        <th style={{ fontWeight: "500" }} colSpan="4">
                          {obj.dateFormat(res?.createdAt)}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: "3px 5px" }} width="50%">
                          {res?.transactionType === "credit"
                            ? "Deposit"
                            : "Withdrawal"}{" "}
                          <strong className={`d-block`}>
                            {res?.amount?.toFixed(2)}
                          </strong>
                        </td>
                        <td style={{ padding: "3px 5px" }} width="50%">
                          Balance{" "}
                          <strong className="d-block">
                            {res?.newBalance?.toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td width="50%" colSpan="4">
                          {res?.forBet != 0 || res?.forBet != "0"
                            ? "-->"
                            : `Agent ->${res?.userData?.username}`}
                          {/* {res?.createdByData?.userType}{" "}
                          {!isEmpty(res?.userData?.username) && (
                            <span className="master-pass">
                              {res?.userData?.username ||
                                res?.userData?.username}
                            </span>
                          )} */}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              );
            })
          : "No Record Found"}
        {data?.hasNextPage && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Button
              onClick={handel}
              style={{ background: "black", border: "none" }}
            >
              View More <IoIosArrowForward />
            </Button>
          </div>
        )}
      </div>
      </Container>
    </SidebarLayout>
  );
};

export default BalanceOverview;
