import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import SidebarLayout from "../components/shared/SidebarLayout";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import AuthContext from "../context/AuthContext";
import { compact, isEmpty, startCase } from "lodash";
const WithdrawHistory = () => {
  const { userCoins, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  const handel = () => {
    setFilter({
      ...filter,
      page: filter?.page + 1,
    });
    getData(
      {
        ...filter,
        page: filter?.page + 1,
      },
      "add"
    );
  };
  const getData = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.withDrawHistory, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results]);
        } else {
          setListing(data?.results);
        }
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const [disable, setDisable] = useState(false);
  const cancelRequest = async (id) => {
    setDisable(true);
    try{
      const { status, data } = await apiPost(apiPath.cancelRequest, {
        requestId: id,
      });
      if (status == 200) {
        if (data?.success) {
          setDisable(false);
          setData(data?.results);
        } else {
          setDisable(false);
        }
      } else {
        setDisable(false);
      }
    } catch(error){
      setDisable(false);
    }
  };

  return (
    <SidebarLayout heading={"Withdraw History"}>
      <div className="p-3 pt-4 inner-sidebar-content d-flex flex-column ">
        <div className="balance-deposit-sec">
          <Table bordered className="rounded overflow-hidden bg-white">
            {listing?.length > 0
              ? listing?.map((res) => {
                  return (
                    <>
                      <thead>
                        <tr>
                          <th colSpan="4">{obj.dateFormat(res?.createdAt)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="50%">
                            Withdraw Type
                            <strong className={`d-block`}>
                              {res.transactionType}
                            </strong>
                          </td>
                          <td width="50%">
                            Bank
                            <strong className={`d-block`}>
                              {res?.bank_name || res.bank}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="50%">
                            Amount
                            <strong className={`d-block`}>{res.amount}</strong>
                          </td>
                          <td width="50%">
                            Status
                            <strong className={`d-block`}>
                              <span
                                className={
                                  res.status == "completed"
                                    ? "text-success"
                                    : res.status == "declined"
                                    ? "text-danger"
                                    : ""
                                }
                              >
                                {startCase(res.status)}
                              </span>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={res?.status == "requested" ? "" : 2}
                            width={res?.status == "requested" ? `50%` : "100%"}
                          >
                            Remark
                            <strong className={`d-block`}>{res.remark}</strong>
                          </td>
                          {res?.status == "requested" && (
                            <td width="50%">
                              <Button
                                disabled={disable}
                                onClick={() => cancelRequest(res?._id)}
                                className="m-1 p-2 btn-submit bg-gradient-primary text-capitalize"
                              >
                                Cancel Request
                              </Button>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </>
                  );
                })
              : "No record Found"}
          </Table>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default WithdrawHistory;
