import React, { useContext, useRef } from "react";
import BetSlipContext from "../../context/BetSlipContext";
import { useNavigate } from "react-router-dom";
import useScroll from "../../components/Scroll";
import { isEmpty } from "lodash";
import { MdKeyboardArrowRight } from "react-icons/md";
const OddEven = ({ data, scrollHeight, user, detail }) => {
  const { betSlipObject, showBetDialog, setBetSlipObject, setShowBetDialog } =
    useContext(BetSlipContext);
  const myRef = useRef(null);
  const naviagte = useNavigate();
  const [scrollId, setScrollId] = useScroll(myRef);
  const handelChange = (odds, res, index, type) => {
    if (!isEmpty(user)) {
      if (odds > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds,
          eventId: detail?.eventId,
          marketId: detail?.marketId,
          teamName: type,
          playType: "line",
          object: {
            ...res,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  return data?.map((res, index) => {
    return (
      <div class="main-analysis mb-2">
        <div
          style={{ scrollMarginTop: scrollHeight }}
          ref={index == scrollId ? myRef : null}
          class="top"
        >
          <div class="toptitle d-inline">{res?.name}</div>
          {/* <div class="min-max d-inline ">
            <span class="desktop-minmax">Min: 1 | Max: 1,000</span>
          </div> */}
        </div>
        <div class="table-responsive analysis-running-market bookmakerbs">
          <div class="container-fluid position-relative">
            <div class="row">
              {(res?.ms == 9 ||
                res?.ms == 2 ||
                res?.ms == 3 ||
                res?.ms == 4) && (
                <span className="suspended-line">
                  {" "}
                  {(res?.ms == 9 ||
                    res?.ms == 2 ||
                    res?.ms == 3 ||
                    res?.ms == 4) &&
                    (res?.ms == 9
                      ? "Ball Running"
                      : res?.ms == 2
                      ? "In Active"
                      : res?.ms == 3
                      ? "Suspended"
                      : res?.ms == 4
                      ? "Closed"
                      : "")}
                </span>
              )}

              {res?.ruunerArray?.map((item) => {
                return (
                  <div
                    class="col-12 p-0 m-0 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-right-left"
                    style={{ borderBottom: "1px solid lightgrey" }}
                  >
                    <div
                      class="d-inline-flex w-100 lottery-p-2 odds"
                      style={{ height: "40px", overflowY: "hidden" }}
                    >
                      <div class="d-inline-block lottery-w75 lottery-inplay v-align-top p-relative">
                        <div class='"d-inline-block single-div-outer'>
                          <span class="in-play-title in-play-title-winnerodds">
                            {item?.name}
                          </span>
                          {(Math?.abs(item?.position) > 0 ||
                            Math.abs(item?.newPosition) > 0) && (
                            <div
                              className={
                                item?.position > 0
                                  ? "text-success line-text"
                                  : "text-danger line-text"
                              }
                            >
                              {Math?.abs(item?.position) > 0
                                ? Math?.abs(item?.position).toFixed(2)
                                : ""}
                              {Math.abs(item?.newPosition) > 0 && (
                                <span
                                  className={
                                    item?.newPosition > 0
                                      ? "text-green"
                                      : "text-red"
                                  }
                                >
                                  <MdKeyboardArrowRight />
                                  {item?.newPosition > 0
                                    ? Math.abs(item?.newPosition).toFixed(2)
                                    : `( ${Math.abs(item?.newPosition).toFixed(
                                        2
                                      )} )`}
                                </span>
                              )}{" "}
                            </div>
                          )}
                        </div>
                        <div class="d-inline-block runnerid float-right p-absolute winner-right4 "></div>
                      </div>
                      <div
                        onClick={() =>
                          handelChange(
                            res?.b1 > 0 ? res?.b1 : 0,
                            res,
                            index,
                            item?.key
                          )
                        }
                        className={`text-center lottery-w25 lottery-inplay btn-back  ${
                          betSlipObject?.teamName == item?.key &&
                          betSlipObject?.odds == res?.b1 &&
                          res?.name == betSlipObject?.object?.name &&
                          showBetDialog
                            ? "line-active"
                            : ""
                        }`}
                      >
                        <div class="">
                          <a class="btn-back border-bottom1 w-100 text-center d-block font-weight-bold ">
                            {res?.b1 > 0 ? res?.b1 : "-"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default OddEven;
