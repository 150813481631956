import React, { useContext, useEffect, useState } from "react";
import { AiFillAlert } from "react-icons/ai";
import SportGames from "./HomeGames/SportGames";
import TableGames from "./HomeGames/TableGames";
import SlotGames from "./HomeGames/SlotGames";
import FishingGames from "./HomeGames/FishingGames";
import Slider from "react-slick";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import Egames from "./HomeGames/Egames";
import { Container } from "react-bootstrap";
import Poker from "./HomeGames/Poker";
import {
  EvolutionGames,
  PokerGames,
  LiveGames,
  VivoGames,
  BetGames,
  AviatorGames,
  popularArray,
  AisaGames,
  Aura,
  NavDepositWithrawData,
} from "../constraints/constants";
import Sports from "../pages/Sports";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";

const GamesSlot = () => {
  const { setGameTab, active, setActive } = useContext(AuthContext);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  var settings1 = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    arrows: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <>
      <div className="games-slot">

        <div className="payment-sec" {...settings1}>
          {NavDepositWithrawData?.map((item) => {
            return (
              <div
                onClick={() => {
                  if (isEmpty(item?.SecondTab)) {
                    setActive(item?.firstTab);
                  } else {
                    setActive(item?.firstTab);
                    setGameTab(item?.SecondTab);
                  }
                }}
                className={`payment-box ${active == item?.firstTab ? "active" : ""}`}
              >
                <figure>
                  <img src={item?.icons} alt="" />
                </figure>
                <span>{item?.title}</span>
              </div>
            );
          })}
        </div>
        {/* </Container> */}
      </div>
      {active === "poker" && (
        <Poker
          data={PokerGames}
          banner={true}
          type={"first"}
          game={"poker"}
          message={""}
        />
      )}
      {active === "evolution" && <Poker data={EvolutionGames} type={"sec"} />}
      {active === "ezugi" && <Poker data={popularArray} type={"sec"} />}
      {/* {active === "sports" && <SportGames />} */}
      {(active === "InPlay" ||
        active === "Cricket" ||
        active === "Tennis" ||
        active === "Soccer") && <Sports />}
      {active === "live" && <Poker data={LiveGames} type={"first"} />}
      {active === "aura" && (
        <Poker data={Aura} type={"sec"} message={"Comming Soom"} />
      )}
      {active === "vivo" && <Poker data={VivoGames} type={"sec"} />}
      {/* {active === "betGames" && <Poker data={BetGames} type={"sec"} />} */}
      {active === "aviator" && (
        <Poker data={AviatorGames} type={"first"} message={""} />
      )}
      {active === "Asia" && <Poker data={AisaGames} type={"sec"} />}
      {active === "table" && <TableGames />}
      {active === "slot" && <SlotGames />}
      {active === "fish" && <FishingGames />}
      {active === "e-game" && <Egames />}
    </>
  );
};

export default GamesSlot;
