import React from "react";
import Slider from "react-slick";
const SportsTab = ({ setTab, tab, type }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
  };
  return (
    <div className="sports-tab-panel py-2 px-0">
      <Slider {...settings}>
        <div>
          <h3
            onClick={() => setTab("exchange")}
            className={tab == "exchange" ? "active" : ""}
          >
            Exchange
          </h3>
        </div>
        <div>
          <h3
            onClick={() => setTab("bookmaker")}
            className={tab == "bookmaker" ? "active" : ""}
          >
            Bookmaker
          </h3>
        </div>
        <div>
          <h3
            onClick={() => setTab("fancy")}
            className={tab == "fancy" ? "active" : ""}
          >
            Fancybet
          </h3>
        </div>
   
     
        <div>
          <h3
            onClick={() => setTab("lottery")}
            className={tab == "lottery" ? "active" : ""}
          >
            Lottery
          </h3>
        </div>
          
        {type !== "casino" && (
          <div>
            <h3
              onClick={() => setTab("casino")}
              className={tab == "casino" ? "active" : ""}
            >
              Casino
            </h3>
          </div>
        )}
        {type == "profitLoss" && (
          <div>
            <h3
              onClick={() => setTab("indianCasion")}
              className={tab == "indianCasion" ? "active" : ""}
            >
              Indian Casion
            </h3>
          </div>
        )}
        {type == "profitLoss" && (
          <div>
            <h3
              onClick={() => setTab("internationalCasino")}
              className={tab == "internationalCasino" ? "active" : ""}
            >
              International Casino
            </h3>
          </div>
        )}
      </Slider>
    </div>
  );
};

export default SportsTab;
