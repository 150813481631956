import React, { useContext, useEffect, useState } from "react";
import GameCard from "../GameCard";
import GameSidebar from "../GameSidebar";
import {
  NewLiveCasino,
  PokerGames,
  gamesLiveArray,
  gamesSelectionArray,
} from "../../constraints/constants";
import { Button } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import GameCardNew from "../GameCardNew";

const Poker = ({ data, banner, type, message, game }) => {
  const { user } = useContext(AuthContext);

  return (
    <div
      className={"games-inner"}
      // className={user ? "games-inner extra-space" : "games-inner"}
    >
      <div className="games-wrapper">
        {banner && <img src={"../assets/images/poker/poker.webp"} />}
        <div className="games-card-inner bg-white">
          <div className="row">
            {data.slice(0, 30)?.map((res, index) => {
              return (
                <GameCardNew
                  key={index}
                  res={res}
                  type={type}
                  message={message}
                  game={game}
                />
              );
            })}
          </div>
        </div>
        {/* <div className="w-100 d-flex justify-content-center align-items-center">
          <Button
            onClick={() => {
              navigate(`/casino?category=poker`);
            }}
            style={{ background: "black", border: "none" }}
          >
            View More <IoIosArrowForward />
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default Poker;
