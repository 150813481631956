import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { FaArrowsAltV } from "react-icons/fa";
import { isEmpty } from "lodash";
import { priceFormat } from "../Utils/constants";
import BackLay from "./BackLay";
import { useNavigate } from "react-router-dom";
import useScroll from "./Scroll";
import { AiOutlineStar } from "react-icons/ai";
import { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import BackLayBookmaker from "./BackLayBookmaker";
const Bookmaker = ({
  backOdds,
  layOdds,
  bookmaker,
  user,
  profileData,
  detail,
  setBetSlipObject,
  setShowBetDialog,
  betSlipObject,
  setLimit,
  displayType,
  showBetDialog,
  scrollHeight,
  socketUrl,
  bookmakerSuspended,
}) => {
  const { setMessage } = useContext(BetSlipContext);
  const naviagte = useNavigate();
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({});
  useEffect(() => {
    let obj = profileData?.matchSetting?.find(
      (res) =>
        res?.type == "bookmaker" &&
        (profileData?.arrayCheck == "details"
          ? true
          : res?.sportType == detail?.gameType)
    );
    if (!isEmpty(obj)) {
      setLocalLimit({
        ...obj,
        maxAmount: displayType == "toss" ? 100000 : obj?.maxAmount,
        minAmount: obj?.minAmount > 0 ? obj?.minAmount : 1,
        maxProfit: displayType == "toss" ? 1000000 : obj?.maxProfit,
      });
    } else {
      setLocalLimit({
        maxProfit: displayType == "toss" ? 1000000 : obj?.maxProfit,
        maxAmount: displayType == "toss" ? 100000 : obj?.maxAmount,
        minAmount: obj?.minAmount > 0 ? obj?.minAmount : 1,
        oddsLimit: 10,
      });
    }
  }, []);
  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (displayType == "bookmaker" ? odds?.rt > 0 : true) {
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: displayType == "bookmaker" ? odds?.rt : 0.98,
          teamName: team,
          betType: type,
          socketUrl: socketUrl,
          playType: displayType == "bookmaker" ? "bookmaker" : "toss",
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: detail?.marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };
  return (
    <div className="bookmaker p-2">
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div
              className="d-flex align-items-center"
              style={{ paddingLeft: "10px" }}
            >
              {/* <div className="fave bg-star-sprit-black animate"></div> */}
              <AiOutlineStar size={20} style={{ margin: "0px 4px" }} />
              {displayType == "bookmaker" ? "Bookmaker" : "Toss"}
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <div className="match-odd-table bg-white p-3 px-2 py-1 pe-0">
              <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
                <span className="text-center w-100">Back</span>
                {displayType == "bookmaker" && (
                  <span className="text-center w-100">Lay</span>
                )}
              </div>

              <ul className="mb-2 rounded position-relative p-0">
                {bookmaker?.map((item, index) => {
                  let odds_back = backOdds
                    ?.filter((todd) => todd?.runnerName == item?.RunnerName)
                    ?.sort((a, b) => {
                      return a - b;
                    });
                  const odds_lay = layOdds
                    ?.filter((todd) => todd?.runnerName == item?.RunnerName)
                    ?.sort((a, b) => {
                      return a - b;
                    });
                  return (
                    <li
                      ref={index == scrollId ? myRef : null}
                      style={{ scrollMarginTop: scrollHeight }}
                    >
                      <h2>
                        {item?.RunnerName}{" "}
                        {(Math?.abs(item?.position) > 0 ||
                          Math.abs(item?.newPosition) > 0) && (
                          <span
                            className={`d-block py-1 ${
                              item?.position > 0
                                ? "text-green team-red-score"
                                : "team-red-score"
                            }`}
                          >
                            {Math?.abs(item?.position) > 0
                              ? Math?.abs(item?.position).toFixed(2)
                              : ""}
                            {Math.abs(item?.newPosition) > 0 && (
                              <span
                                className={
                                  item?.newPosition > 0 ? "text-green" : ""
                                }
                              >
                                {" "}
                                {">"} {Math.abs(item?.newPosition).toFixed(2)}
                              </span>
                            )}
                          </span>
                        )}
                      </h2>
                      {/* {displayType == "bookmaker" &&
                      !bookmakerSuspended && (backOdds?.length > 0 || layOdds?.length > 0) &&
                      (backOdds[index]?.ms != 1 || layOdds[index]?.ms != 1) ? (
                        <div
                          className={`overlay-match overlay-match-bookmaker ${
                            index == 0
                              ? "top-overlay-match"
                              : "bottom-overlay-match"
                          }`}
                        >
                          {backOdds[index]?.ms == 9 || layOdds[index]?.ms == 9
                            ? "Ball Running"
                            : backOdds[index]?.ms == 2 ||
                              layOdds[index]?.ms == 2
                            ? "In Active"
                            : backOdds[index]?.ms == 3 ||
                              layOdds[index]?.ms == 3
                            ? "Suspended"
                            : backOdds[index]?.ms == 4 ||
                              layOdds[index]?.ms == 4
                            ? "Closed"
                            : "Suspended"}
                        </div>
                      ) : (
                        ""
                      )} */}
                      {displayType == "bookmaker" ? (
                        <BackLayBookmaker
                          component={"bookmaker"}
                          index={index}
                          item={item}
                          handelChange={handelChange}
                          odds_lay={odds_lay}
                          odds_back={odds_back}
                          betPlaceAccept={true}
                          limit={localLimit}
                        />
                      ) : (
                        <div className={`lay-back-table toss-back`}>
                          <div
                            onClick={() => {
                              handelChange(
                                1.98,
                                "back",
                                item?.RunnerName,
                                item,
                                index
                              );
                            }}
                            className={`back ${
                              betSlipObject?.teamName == item?.RunnerName &&
                              betSlipObject?.odds == 0.98 &&
                              betSlipObject?.playType == "toss" &&
                              showBetDialog
                                ? "back-active"
                                : ""
                            }`}
                          >
                            <span>
                              <strong>0.98</strong>
                            </span>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
                {displayType == "bookmaker" &&
                  (bookmakerSuspended ||
                    detail?.sport_setting?.status == "inActive") && (
                    <div className="overlay-match">Suspend</div>
                  )}
              </ul>
              <div className="market-depth-sec d-flex justify-content-end">
                <div className="d-flex align-items-center min-max">
                  <FaArrowsAltV />
                  <span>min/max</span>
                  <strong>
                    {localLimit?.minAmount || 1}/
                    {priceFormat(localLimit?.maxAmount) || 100}
                  </strong>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Bookmaker;
