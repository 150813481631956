import React from "react";
import { Accordion, Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import { isEmpty } from "lodash";
import { priceFormat } from "../Utils/constants";
import BackLayOuter from "./BackLayOuter";
const SportAccordian = ({ item, index, score, matchOdds, length }) => {
  const navigate = useNavigate();
  return (
    <>
      <div key={index} className="bookmaker p-sm-2 sport-accordian">
        <Accordion activeKey={length} alwaysOpen={true}>
          <Accordion.Item eventKey={index}>
            <Accordion.Header style={{ display: "none" }}>
              {" "}
              <span className="sport-counter bg-yellow ">
                {item?.matchList?.length}
              </span>
              {item?.seriesName}
            </Accordion.Header>
            <Accordion.Body
              style={{ borderBottom: "2px solid #DEE2E6" }}
              className="p-0"
            >
              {item?.matchList
                ?.filter((el) => !el.eventName.includes(" T10 "))
                .map((object, index) => {
                  let Odds =
                    matchOdds?.length > 0
                      ? matchOdds?.find((res) => {
                          return res?.mi == object?.marketId;
                        })
                      : {};
                  return (
                    <div className="sport-accord-body">
                      <div className="position-relative">
                        <div
                          onClick={() =>
                            navigate(
                              `/full-market/${object?.marketId}/${object?.eventId}`
                            )
                          }
                          style={{ padding: "0 5px" }}
                          className="d-flex justify-content-between align-items-center mb-2"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                              width: "100%",
                            }}
                          >
                            <div
                              onClick={() =>
                                navigate(
                                  `/full-market/${object?.marketId}/${object?.eventId}`
                                )
                              }
                              style={{ width: "30%", flexDirection: "column" }}
                              className="icon-sports d-flex"
                            >
                              <div>
                                <img
                                  src="../assets/images/casino-icon/youtube.png"
                                  alt=""
                                />
                                <>
                                  {object?.eventType == 4 &&
                                    object?.hasFancy && (
                                      <>
                                        <img
                                          src="../assets/images/casino-icon/f.png"
                                          alt=""
                                        />
                                        <img
                                          src="../assets/images/casino-icon/b.png"
                                          alt=""
                                        />
                                      </>
                                    )}
                                  {object?.eventType !== 4 &&
                                    object?.hasBookmaker && (
                                      <img
                                        src="../assets/images/casino-icon/b.png"
                                        alt=""
                                      />
                                    )}
                                </>
                              </div>
                              <span
                                style={{ alignSelf: "end" }}
                                className={`in-play ms-1 ${
                                  object?.status == "in_play"
                                    ? "in-play"
                                    : obj.isToday(object?.eventDateTime)
                                    ? "today"
                                    : "tommorow"
                                }`}
                              >
                                {object?.status == "in_play"
                                  ? "In-play"
                                  : obj.isToday(object?.eventDateTime)
                                  ? `${obj?.matchDateTime(
                                      object?.eventDateTime,
                                      "same"
                                    )}`
                                  : obj?.dateFormat(object?.eventDateTime)}
                              </span>
                            </div>
                            <div
                              className="d-flex flex-column"
                              style={{ width: "70%" }}
                            >
                              <span
                                style={{ fontSize: "13px", fontWeight: "700" }}
                              >
                                {object?.eventName}
                              </span>
                              <span
                                style={{ fontSize: "11px", fontWeight: "500" }}
                              >
                                {obj?.dateFormat(object?.eventDateTime)}|{" "}
                                {item?.seriesName}
                              </span>
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            {score?.length > 0 &&
                              !isEmpty(
                                score?.find((res) => {
                                  return res?.eventId == object?.eventId;
                                })
                              ) && (
                                <div className="counter">
                                  {
                                    score?.find((res) => {
                                      return res?.eventId == object?.eventId;
                                    })?.score
                                  }
                                </div>
                              )}
                          </div>
                        </div>
                        {object?.runners?.length > 0 && !isEmpty(Odds) && (
                          <div className="sports-team-table">
                            <div className="match-odd-table match-odd-table-new w-100">
                              <ul className="rounded position-relative w-100 p-0 m-0">
                                <li
                                  onClick={() =>
                                    navigate(
                                      `/full-market/${object?.marketId}/${object?.eventId}`
                                    )
                                  }
                                >
                                  {(object?.runners?.length == 2
                                    ? [
                                        object?.runners[0],
                                        {
                                          SelectionId: "",
                                          RunnerName: "",
                                        },
                                        object?.runners[1],
                                      ]
                                    : object?.runners
                                  )?.map((res) => {
                                    let odds_lay = Odds?.rt?.filter(
                                      (todd) =>
                                        !todd?.ib &&
                                        todd?.ri == res?.SelectionId
                                    );
                                    let odds_back = Odds?.rt?.filter(
                                      (todd) =>
                                        todd?.ib && todd?.ri == res?.SelectionId
                                    );
                                    return (
                                      <BackLayOuter
                                        component={"betfair"}
                                        type="sport"
                                        index={index}
                                        item={object}
                                        odds_lay={odds_lay}
                                        odds_back={odds_back}
                                      />
                                    );
                                  })}
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default SportAccordian;
