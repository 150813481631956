import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../components/shared/Layout";
import GameMenu from "../components/GameMenu";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { FaAngleLeft } from "react-icons/fa";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
const Leagues = () => {
  const navigate = useNavigate();
  const { gameTab, setGameTab } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [selected, setSelected] = useState({});
  const getData = async (game = gameTab) => {
    const { status, data } = await apiGet(apiPath.seriesList, {
      gameType: game,
    });
    if (data?.success) {
      setList(data?.results);
      // setUpdatedList(() => {
      //   if (gameTab == "all") {
      //     return data?.results;
      //   } else {
      //    return data?.results?.filter((res) => {
      //       return res?.gameType == gameTab;
      //     });
      //   }
      // });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   setUpdatedList(() => {
  //     if (gameTab == "all") {
  //       return list;
  //     } else {
  //      return list?.filter((res) => {
  //         return res?.gameType == gameTab;
  //       });
  //     }
  //   });
  // }, [gameTab]);


  const change = (key) => {
    getData(key);
    setGameTab(key);
  };
  return (
    <div>
      {" "}
      <LayoutComponent visibilityType={true}>
        {isEmpty(selected) && (
          <div>
            <GameMenu game={gameTab} setGame={change} />
            <div className="d-flex p-3 leage-list main-casino-wrapper">
              {/* <aside className="text-center">
                <ul className="p-0 mb-0">
                  <li>C</li>
                  <li>I</li>
                  <li>O</li>
                </ul>
              </aside> */}

              <div
                className={`main-list w-100 ${
                  list?.length > 7 ? "mb-5" : ""
                }`}
              >
                <h6 className="mb-3">Popular</h6>
                {list?.map((item, index) => {
                  return (
                    <div className="w-100 mb-3 match-list">
                      <ul className="p-0 m-0">
                        <li onClick={() => setSelected(item)}>
                          {item?.seriesName}
                        </li>
                        {/* <li>International Twenty20 Matches</li>
                        <li>International Twenty20 Matches</li>
                        <li>International Twenty20 Matches</li>
                        <li>International Twenty20 Matches</li> */}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {/* second-level */}
        {!isEmpty(selected) && (
          <div className="second-level-leage">
            <div className="balance-label position-relative text-start ps-5">
              <button
                onClick={() => setSelected({})}
                className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
              >
                <FaAngleLeft className="fs-3" />
              </button>
              {selected?.seriesName}
            </div>

            <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
              <div
                className={`main-list w-100 ${
                  selected?.matchList?.length > 7 ? "mb-5" : ""
                }`}
              >
                {selected?.matchList?.map((item, index) => {
                  return (
                    <div className="w-100 mb-3 match-list">
                      <ul className="p-0 m-0">
                        <li
                          onClick={() =>
                            navigate(
                              `/full-market/${item?.marketId}/${item?.eventId}`
                            )
                          }
                        >
                          {item?.runners?.map((res) => {
                            return (
                              <span className="d-block">{res?.RunnerName}</span>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {/* second-level */}
      </LayoutComponent>
    </div>
  );
};

export default Leagues;
