import React, { useContext } from "react";
import HomeBanner from "../components/HomeBanner";
import GamesSlot from "../components/GamesSlot";
import LayoutComponent from "../components/shared/Layout";
import { Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import CurrentNews from "../components/CurrentNews";
import { Container } from "react-bootstrap";
import BeforeLogin from "./BeforeLogin";
import WifiLoader from "../components/WifiLoader";

const Home = () => {
  const { messagelist, setAnnouncement, user, banner } =
    useContext(AuthContext);
  return (
    <div>
      <LayoutComponent visibilityType={true}>
        {messagelist?.length > 0 && (
          <CurrentNews
            message={messagelist}
            setAnnouncement={setAnnouncement}
          />
        )}
        <HomeBanner banner={banner} messagelist={messagelist} />
        <div className="position-relative">
          {/* <Outlet /> */}
          <GamesSlot />
        </div>
      </LayoutComponent>
    </div>
  );
};

export default Home;
