import React, { useContext } from "react";
import Slider from "react-slick";
import { bannerSlider } from "../constraints/constants";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
const HomeBanner = ({ banner, messagelist }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  return (
    <div
      style={{ marginTop: messagelist?.length > 0 ? "0px" : "40px" }}
      className={"home-banner-sec home-login"}
    >
      <Slider {...settings}>
        {(banner?.length > 0 ? banner : bannerSlider)?.map((item, index) => {
          return (
            <>
              <div
                onClick={() => {
                  if (isEmpty(user)) {
                    navigate("/login");
                  }
                }}
              >
                <img
                  style={{ height: "120px", objectFit: "cover" }}
                  src={
                    banner?.length > 0
                      ? `${process.env.REACT_APP_API_BASE_URL}${item?.banner_path}`
                      : item?.img
                  }
                  alt=""
                />
              </div>
            </>
          );
        })}
      </Slider>
    </div>
  );
};

export default HomeBanner;
