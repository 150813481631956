import React from "react";
import { Form,Container } from "react-bootstrap";
import SidebarLayout from "../components/shared/SidebarLayout";

const Settings = () => {
  return (
    <SidebarLayout heading={"Setting"}>
      <div className="pt-0 inner-sidebar-content">
       <Container>
       <Form className="p2transfer-form login-panel setting-panel">
          <div className="mb-2">
            <Form.Label>Odds</Form.Label>

            <div className="bg-white p-2 rounded py-3">
              <Form.Check
                type="switch"
                id="odds"
                label="Highlight when odds change"
              />
            </div>
          </div>

          <div>
            <Form.Label>Events Widget</Form.Label>

            <div className="bg-white p-2 rounded py-3">
              <Form.Check
                type="switch"
                id="Widget"
                label="Show in Full-Markets"
              />
            </div>
          </div>
        </Form>
       </Container>
      </div>
    </SidebarLayout>
  );
};

export default Settings;
