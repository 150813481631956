import React, { useContext, useEffect } from "react";
import BetSlipContext from "../context/BetSlipContext";
import { RxCross2 } from "react-icons/rx";
import OutsideClickHandler from "react-outside-click-handler";
import { Button } from "react-bootstrap";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import AuthContext from "../context/AuthContext";

const MessageLottery = () => {
  const { setMessageLottery, messageLotter } = useContext(AuthContext);
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setMessageLottery({ status: false });
      }}
    >
      <div
        className={`message ${
          messageLotter?.errorType == "error" ? "error" : "success"
        }`}
      >
        <div className="market-title">
          <h4>
            {messageLotter?.errorType == "error" ? (
              <BiErrorCircle size={25} />
            ) : (
              <AiOutlineCheckCircle size={25} />
            )}{" "}
            {messageLotter?.errorType == "error" ? "Error" : "Success"}
          </h4>
          <Button
            onClick={() => setMessageLottery({ status: false })}
            className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
          >
            <RxCross2 />
          </Button>
        </div>
        <div className="messageBox">
          {messageLotter?.errorType == "error" ? (
            <div className="inner-messageBox">
              <p>{messageLotter?.message}</p>
            </div>
          ) : (
            <div className="inner-messageBox placed-bet">
              {/* <span
                className={
                  messageLotter?.betType == "No" || messageLotter?.betType == "Yes"
                    ? messageLotter?.betType == "No"
                      ? "lay"
                      : "back"
                    : messageLotter?.betType
                }
              >
                {messageLotter?.betType}
              </span>
              <span className="line">
                {messageLotter?.teamName || messageLotter?.object?.runnerName}
              </span>
              <span className="line">INR {messageLotter?.bidPrice}</span>
              <span>{messageLotter?.odds}</span> */}
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MessageLottery;
