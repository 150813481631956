import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../components/shared/Layout";
import SportAccordian from "../components/SportAccordian";
import { Container, Row, Col } from "react-bootstrap";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import NoEvent from "../components/NoEvent";
import { io } from "socket.io-client";
import SearchBar from "../components/SearchBar";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty, startCase } from "lodash";
const Sports = () => {
  const { gameTab, keyTime, user, profileData, searchTab } =
    useContext(AuthContext);
  const { setLimit } = useContext(BetSlipContext);
  const [list, setList] = useState([]);
  const [activeKeys, setActiveKeys] = useState([0]);
  const [updated, setUpdated] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [activeKeysAll, setActiveKeysAll] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [allOdds, setAllOdds] = useState([]);
  const getOdds = async (id) => {
    let array = id
      ?.map((res) => {
        return res?.matchList?.map((item) => {
          return item?.marketId;
        });
      })
      .flat();
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${array?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setAllOdds(response_users.results);
        }
      }
    }
  };

  const arrayLength = (list) => {
    return Array.from(Array(list?.length).keys());
  };

  const sortBy = (array) => {
    return array?.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  const getData = async () => {
    const { status, data } = await apiGet(apiPath.seriesList, {
      gameType: gameTab == "all" ? "" : gameTab,
      type: gameTab == "all" ? "in_play" : "",
    });
    if (data?.success) {
      if (gameTab == "all") {
        setActiveKeys([0]);
        let obj = {
          cricket:
            filteredArray(
              sortBy(data?.results?.filter((res) => res?.gameType == "cricket"))
            ) || [],
          tennis:
            filteredArray(
              sortBy(data?.results?.filter((res) => res?.gameType == "tennis"))
            ) || [],
          soccer:
            filteredArray(
              sortBy(data?.results?.filter((res) => res?.gameType == "soccer"))
            ) || [],
          all: sortBy(data?.results),
        };
        setUpdated(obj);
        setActiveKeysAll({
          cricket: arrayLength(obj?.cricket),
          tennis: arrayLength(obj?.tennis),
          soccer: arrayLength(obj?.soccer),
        });
        getOdds(data?.results);
        setList([]);
      } else {
        let result = filteredArray(sortBy(data?.results));
        setList(result);
        getOdds(result);
        setActiveKeys(arrayLength(data?.results));
        setUpdated({});
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(updated) || list?.length > 0) {
      let interval = setInterval(() => {
        getOdds(!isEmpty(updated) ? updated?.all : list);
      }, 15000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [updated, list]);

  const filteredArray = (array) => {
    if (array?.length > 0) {
      let check = array?.filter((res) => {
        return res?.seriesName == "Others";
      });
      if (check?.length > 1) {
        let find = check?.find((res) => {
          return res?.seriesName == "Others";
        });
        return [
          ...array?.filter((res) => {
            return res?.seriesName !== "Others";
          }),
          {
            ...find,
            matchList: check
              ?.map((res) => {
                return res?.matchList;
              })
              .flat(),
          },
        ];
      } else {
        return array;
      }
    }
  };
  useEffect(() => {
    getData();
  }, [gameTab, keyTime]);

  const handleSelect = (event, type) => {
    if (gameTab !== "all") {
      if (activeKeys.includes(event)) {
        setActiveKeys(activeKeys.filter((res, index) => res !== event));
      } else {
        setActiveKeys([...activeKeys, event]);
      }
    } else {
      updateActive(event, type);
    }
  };
  const obj = {
    cricket: activeKeysAll?.cricket || [],
    tennis: activeKeysAll?.tennis || [],
    soccer: activeKeysAll?.soccer || [],
  };
  const updateActive = (event, type) => {
    if (obj[type].includes(event)) {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: obj[type].filter((res, index) => res !== event),
        });
      }
    } else {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: [...activeKeysAll?.cricket, event],
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: [...activeKeysAll?.tennis, event],
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: [...activeKeysAll?.soccer, event],
        });
      }
    }
  };

  // Socket
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${"https://bdy247.com:5000"}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(`${"https://bdy247.com:5000"}?user_id=${randomId}`, {
          transports: ["websocket"],
          forceNew: true,
        });
      }
      setSocketObj(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  return (
    <div className="bg-skylight-outer">
      {/* <LayoutComponent visibilityType={true}> */}
      {searchTab ? (
        <SearchBar />
      ) : (
        <>
          <div className="bg-skylight">
            <Container className="p-0">
              {gameTab !== "all" && (
                <div
                  style={{ background: "#171135" }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <h6 className="sports-heading">{startCase(gameTab)}</h6>
                </div>
              )}
            </Container>
          </div>

          {gameTab == "all" ? (
            <>
              {updated?.cricket?.length > 0 && (
                <div className="bg-skylight">
                  <Container className="p-0">
                    <div
                      style={{ background: "#171135" }}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <h6 className="sports-heading">Cricket</h6>
                    </div>

                    {updated?.cricket?.length > 0
                      ? updated?.cricket?.map((item, index) => {
                          return (
                            <SportAccordian
                              profileData={profileData}
                              activeKeys={activeKeysAll?.cricket}
                              handleSelect={handleSelect}
                              user={user}
                              length={arrayLength(updated?.cricket)}
                              matchOdds={allOdds}
                              type="cricket"
                              keyTime={keyTime}
                              item={item}
                              index={index}
                              setLimit={setLimit}
                              score={score}
                            />
                          );
                        })
                      : ""}
                  </Container>
                </div>
              )}
              {updated?.tennis?.length > 0 && (
                <div className="bg-skylight">
                  <Container className="p-0">
                    <div
                      style={{ background: "#171135" }}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <h6 className="sports-heading">Tennis</h6>
                    </div>
                    {updated?.tennis?.length > 0
                      ? updated?.tennis?.map((item, index) => {
                          return (
                            <SportAccordian
                              profileData={profileData}
                              user={user}
                              keyTime={keyTime}
                              length={arrayLength(updated?.tennis)}
                              activeKeys={activeKeysAll?.tennis}
                              type="tennis"
                              matchOdds={allOdds}
                              handleSelect={handleSelect}
                              item={item}
                              index={index}
                              setLimit={setLimit}
                              score={score}
                            />
                          );
                        })
                      : ""}
                  </Container>
                </div>
              )}
              {updated?.soccer?.length > 0 && (
                // bg-toper
                <div className="bg-skylight ">
                  <Container className="p-0">
                    <div
                      style={{ background: "#171135" }}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <h6 className="sports-heading">Soccer</h6>
                    </div>
                    {updated?.soccer?.length > 0
                      ? updated?.soccer?.map((item, index) => {
                          return (
                            <SportAccordian
                              profileData={profileData}
                              user={user}
                              keyTime={keyTime}
                              length={arrayLength(updated?.soccer)}
                              activeKeys={activeKeysAll?.soccer}
                              type="soccer"
                              matchOdds={allOdds}
                              handleSelect={handleSelect}
                              item={item}
                              index={index}
                              setLimit={setLimit}
                              score={score}
                            />
                          );
                        })
                      : ""}
                  </Container>
                </div>
              )}
            </>
          ) : (
            <div className="bg-skylight">
              <Container className="p-0">
                {list?.length > 0 ? (
                  list?.map((item, index) => {
                    return (
                      <SportAccordian
                        profileData={profileData}
                        user={user}
                        length={arrayLength(list)}
                        keyTime={keyTime}
                        activeKeys={activeKeys}
                        handleSelect={handleSelect}
                        type="all"
                        matchOdds={allOdds}
                        item={item}
                        list={list}
                        index={index}
                        setLimit={setLimit}
                        score={score}
                      />
                    );
                  })
                ) : (
                  <NoEvent />
                )}
              </Container>
            </div>
          )}
        </>
      )}
      {/* </LayoutComponent> */}
    </div>
  );
};

export default Sports;
