import React from 'react'
import { Col, Row } from "react-bootstrap";
import SportsCard from '../components/SportsCard';
import CasinoCard from '../components/CasinoCard';
import LiveCasino from '../components/LiveCasino';
const BeforeLogin = () => {
  return (
    <div className="live-casnios p-2">
     <SportsCard/>
     <CasinoCard/>
     <LiveCasino/>
   </div>
  )
}

export default BeforeLogin