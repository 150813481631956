import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  let location = useLocation();
  if (isEmpty(user)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
