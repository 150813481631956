import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";
import { RiLuggageDepositFill } from "react-icons/ri";
import { MdAccountBalanceWallet } from "react-icons/md";
import { TbTransferIn } from "react-icons/tb";
import { BiTransfer } from "react-icons/bi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaUsersBetweenLines } from "react-icons/fa6";
import AuthContext from "../context/AuthContext";
const MyBets = ({ setShowBet, handleShowBet2 }) => {
  const navigate = useNavigate();
  const { profileData, totalCount } = useContext(AuthContext);
  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowBet(false);
        }}
      >
        <div className="market-depth-modal">
          <div className="market-title">
            <h4>My Account</h4>
            <Button
              onClick={() => setShowBet(false)}
              className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
            >
              <RxCross2 />
            </Button>
          </div>
          <div className="market-depth-body" style={{ overflowY: "unset" }}>
            {" "}
            <div
              class="member-menu-box member-list"
              style={{
                margin: "0",
                borderBottom: "2px solid rgb(236, 234, 234)",
              }}
            >
              <ul class="align-center">
                <li
                  style={{ borderBottom: "none" }}
                  class="deposit"
                  onClick={() => {
                    setShowBet(false);
                    navigate("/deposit");
                  }}
                >
                  {" "}
                  <a>
                    <span class="item-icon">
                      <RiLuggageDepositFill size={25} color="#18b0c8" />
                    </span>
                    <p>Deposit</p>
                  </a>
                </li>
                <li
                  style={{ borderBottom: "none" }}
                  class="withdrawal"
                  onClick={() => {
                    setShowBet(false);
                    navigate("/withdraw");
                  }}
                >
                  {" "}
                  <a>
                    <span class="item-icon">
                      <MdAccountBalanceWallet size={25} color="#18b0c8" />
                    </span>
                    <p>Withdrawal</p>
                  </a>
                </li>
              </ul>
            </div>{" "}
            <div
              class="member-menu-box member-list"
              style={{
                margin: "0",
                borderBottom: "2px solid rgb(236, 234, 234)",
              }}
            >
              <ul class="align-center">
                <li
                  style={{ borderBottom: "none" }}
                  class="deposit"
                  onClick={() => {
                    setShowBet(false);
                    navigate("/deposit-history");
                  }}
                >
                  {" "}
                  <a>
                    <span class="item-icon">
                      <BiTransfer size={25} color="#18b0c8" />
                    </span>
                    <p>Deposit History</p>
                  </a>
                </li>
                <li
                  style={{ borderBottom: "none" }}
                  class="withdrawal"
                  onClick={() => {
                    setShowBet(false);
                    navigate("/withdraw-history");
                  }}
                >
                  {" "}
                  <a>
                    <span class="item-icon">
                      <TbTransferIn size={25} color="#18b0c8" />
                    </span>
                    <p>Withdrawal History</p>
                  </a>
                </li>
              </ul>
            </div>{" "}
            <div class="member-menu-box member-list">
              <ul class="align-center">
                <li
                  style={{ borderBottom: "none" }}
                  class="deposit"
                  onClick={() => {
                    setShowBet(false);
                    handleShowBet2();
                    // navigate("/deposit-history");
                  }}
                >
                  {" "}
                  <a className="position-relative">
                    <span class="item-icon">
                      <FaUsersBetweenLines size={25} color="#18b0c8" />
                    </span>
                    <p>My Bets</p>
                    <span
                      style={{
                        position: "absolute",
                        top: "-20%",
                        right: "20%",
                      }}
                      className="ms-2 text-white bg-yellow d-block p-2 py-0 fs-6 rounded"
                    >
                      {profileData?.totalBetCount || 0}
                    </span>
                  </a>
                </li>
                <li
                  style={{ borderBottom: "none" }}
                  class="withdrawal"
                  onClick={() => {
                    setShowBet(false);
                    navigate("/customer-support");
                  }}
                >
                  {" "}
                  <a className="position-relative">
                    <span class="item-icon">
                      <MdOutlineSupportAgent size={25} color="#18b0c8" />
                    </span>
                    <p>Customer Support</p>{" "}
                    <span
                      style={{
                        position: "absolute",
                        top: "-20%",
                        right: "25%",
                      }}
                      className="ms-2 text-white bg-yellow d-block p-2 py-0 fs-6 rounded"
                    >
                      {totalCount || 0}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default MyBets;
