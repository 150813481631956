import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import AuthContext from '../context/AuthContext'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
const CasinoCard = () => {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  return (
    <div className="casino-racing mb-md-4 mb-2">
      <Row className="gx-md-2 gx-0">
        <Col xs={3} className='mb-2'>
          <div onClick={() => {
            if (isEmpty(user)) {
              navigate('/login')
            }
          }} className="text-center racing-sec">
            {/* <h2>Cricket</h2> */}
            <figure>
              <img src="../assets/images/s3.webp" alt="" />
            </figure>
          </div>
        </Col>
        <Col xs={3} className='mb-2'>
          <div onClick={() => {
            if (isEmpty(user)) {
              navigate('/login')
            }
          }} className="text-center racing-sec">
            {/* <h2>Soccer</h2> */}
            <figure>
              <img src="../assets/images/s4.webp" alt="" />
            </figure>
          </div>
        </Col>

        <Col xs={3} className='mb-2'>
          <div onClick={() => {
            if (isEmpty(user)) {
              navigate('/login')
            }
          }} className="text-center racing-sec">
            {/* <h2>Tennis</h2> */}
            <figure>
              <img src="../assets/images/s5.webp" alt="" />
            </figure>
          </div>
        </Col>

        <Col xs={3} className='mb-2'>
          <div onClick={() => {
            if (isEmpty(user)) {
              navigate('/login')
            }
          }} className="text-center racing-sec">
            {/* <h2>Virtual Cricket</h2> */}
            <figure>
              <img src="../assets/images/s6.webp" alt="" />
            </figure>
          </div>
        </Col>

      </Row>
    </div>
  )
}

export default CasinoCard