import { casinoimg } from '../constraints/constants'
import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import AuthContext from '../context/AuthContext'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
const LiveCasino = () => {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  return (
    <div>
      <div className='page-heading'>
        <h4>Live Casino</h4>
      </div>
      <Row className="gx-2 casino-row">
        {
          casinoimg.map((item, i) => {
            return <Col xs={3} className="mb-2">
              <div onClick={() => {
                if (isEmpty(user)) {
                  navigate('/login')
                }
              }} className="figure">
                <img src={item?.img} alt="" />
              </div>
            </Col>
          })
        }
      </Row>
    </div>
  )
}

export default LiveCasino