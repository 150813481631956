import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  Table,
  Container,
} from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import SidebarLayout from "../../components/shared/SidebarLayout";
import SportsTab from "./SportsTab";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import { isEmpty, startCase } from "lodash";
import obj from "../../Utils/helpers";
import objConstant from "../../Utils/constants";
import AuthContext from "../../context/AuthContext";
import { BiSolidRightArrow } from "react-icons/bi";
import NoEvent from "../../components/NoEvent";
const CurrentBets = () => {
  const { user } = useContext(AuthContext);
  const [showBet, setShowBet] = useState("");
  const [exchange, setExchange] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [sportBook, setSportBook] = useState([]);
  const [bookmaker, setBookmaker] = useState([]);
  const [tab, setTab] = useState("exchange");
  const [matched, setMatched] = useState(true);
  const [lottery, setLottery] = useState([]);
  const handleShowBet = (data) => {
    if (data == showBet) {
      setShowBet("");
    } else {
      setShowBet(data);
    }
  };
  const getExchangeBet = async () => {
    const { status, data } = await apiGet(apiPath.getCurrentBetsExchanges);
    if (status == 200) {
      if (data?.success) {
        setExchange(data?.results);
      }
    }
  };
  const getFancyBet = async () => {
    const { status, data } = await apiGet(apiPath.getFancyBets);
    if (status == 200) {
      if (data?.success) {
        setFancy(data?.results);
      }
    }
  };
  const getBookmakerBet = async () => {
    const { status, data } = await apiGet(apiPath.getBookmakerBets);
    if (status == 200) {
      if (data?.success) {
        setBookmaker(data?.results);
      }
    }
  };
  const getSportBookBet = async () => {
    const { status, data } = await apiGet(apiPath.getSportsBook);
    if (status == 200) {
      if (data?.success) {
        setSportBook(data?.results);
      }
    }
  };

  const getLotteryBet = async () => {
    const { status, data } = await apiGet(apiPath.getLottery);
    if (status == 200) {
      if (data?.success) {
        setLottery(data?.results);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      // getSportBookBet();
      getBookmakerBet();
      getFancyBet();
      getExchangeBet();
      getLotteryBet();
    }
  }, [user]);

  let object = {
    exchange: matched ? exchange?.matched : exchange?.unMatched,
    fancy: fancy,
    sportsBook: sportBook,
    bookmaker: bookmaker,
  };

  useEffect(() => {
    setMatched(true);
  }, [tab]);

  return (
    <SidebarLayout heading={"Current Bets"}>
      <div className="pt-0 inner-sidebar-content">
        <SportsTab type="current" setTab={setTab} tab={tab} />

        <div className="pt-3 select-container">
          <Container className="d-flex flex-wrap justify-content-between align-items-center p-0">
            <DropdownButton
              id="abc"
              title={`Bet Status ${!matched ? "Un-" : ""}Matched`}
              className="mb-2 w-100"
            >
              <Dropdown.Item onClick={() => setMatched(true)}>
                Matched
              </Dropdown.Item>
              {tab == "exchange" && (
                <Dropdown.Item onClick={() => setMatched(false)}>
                  Un-Matched
                </Dropdown.Item>
              )}
            </DropdownButton>
            <Form>
              {["checkbox"].map((type) => (
                <div
                  key={`default-${type}`}
                  className="d-flex align-items-center justify-content-end order-checkbox"
                >
                  <Form.Label className="text-yellow pe-2 pb-0 mb-0">
                    Order By
                  </Form.Label>
                  <Form.Check // prettier-ignore
                    type={type}
                    id={`default-${type}`}
                    label={"Bet Placed"}
                    className="text-white d-flex"
                  />

                  <Form.Check
                    type={type}
                    label={"Market"}
                    className="text-white d-flex"
                    id={`disabled-default-${type}`}
                  />
                </div>
              ))}
            </Form>
          </Container>
        </div>

        {tab == "lottery" ? (
          <div className="p-3">
            {lottery?.map((res, index) => {
              return (
                <div className="all-bets position-relative" key={res?._id}>
                  <div
                    style={{ borderBottom: "8px solid #9cb1bd" }}
                    className={
                      showBet
                        ? "current-bets-table mt-3 show"
                        : "current-bets-table mt-3"
                    }
                  >
                    <Table
                      bordered
                      className="rounded overflow-hidden bg-white mb-0 odds-table"
                    >
                      <thead>
                        <tr>
                          <th colSpan="4">
                            {/* <span style={{ marginRight: "50px" }}></span> */}
                            Bet Placed
                            <BiSolidRightArrow className="arrow-icon" />
                            <span className="master-pass">
                              {obj.dateFormat(res?.timeInserted)}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="lay-back">
                          <td colSpan="3">
                            {" "}
                            <span
                              style={{ background: "#d4e0e5" }}
                              className={`bet-name`}
                            >
                              {`${startCase(res?.lotteryName)} Digit`}
                            </span>
                            {res?.bets[0]?.matchName}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Bhav{" "}
                            <strong className="d-block">
                              {parseFloat(res?.bhav).toFixed(2)}
                            </strong>
                          </td>
                          {/* <td>
                            Avg. Odds{" "}
                            <strong className="d-block">
                              {parseFloat(res?.bhav).toFixed(2)}
                            </strong>
                          </td> */}
                          <td>
                            Total Bet Amount (INR)
                            <strong className="d-block">{res?.amount}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Bet ID</td>
                          <td colSpan="2">{res?.matchBetId}</td>
                        </tr>
                        {/* <tr>
                          <td>Matched Date</td>
                          <td colSpan="2">
                            {obj.dateFormat(res?.timeInserted)}
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>
                    {showBet == res?._id && (
                      <>
                        {res?.bets?.map((item) => {
                          return (
                            <Table className="bets-table  mb-0">
                              <thead>
                                <th colSpan="4">
                                  <span
                                    style={{ background: "#9cb1bd" }}
                                    className=""
                                  >
                                    Selection : {item?.runnerName}
                                  </span>

                                  <span
                                    style={{ background: "#9cb1bd" }}
                                    className=""
                                  >
                                    Bet Amount :{" "}
                                    {parseFloat(item?.amount).toFixed(2)}
                                  </span>
                                </th>
                              </thead>
                              <tbody style={{ background: "#d4e0e5" }}>
                                <tr>
                                  {/* <td
                                    style={{
                                      background: "#d4e0e5",
                                      borderRight: "1px solid #9cb1bd",
                                    }}
                                  >
                                    Amount
                                    <strong className="d-block">
                                      {parseFloat(item?.amount).toFixed(2)}
                                    </strong>
                                  </td> */}
                                  <td
                                    style={{
                                      background: "#d4e0e5",
                                      borderRight: "1px solid #9cb1bd",
                                    }}
                                  >
                                    Loss Amount
                                    <strong className="d-block text-danger">
                                      {parseFloat(item?.loseAmount).toFixed(2)}
                                    </strong>
                                  </td>{" "}
                                  <td
                                    style={{
                                      background: "#d4e0e5",
                                      borderRight: "1px solid #9cb1bd",
                                    }}
                                  >
                                    Profit Amount
                                    <strong className="d-block text-success">
                                      {parseFloat(item?.profitAmount).toFixed(
                                        2
                                      )}
                                    </strong>
                                  </td>
                                  <td style={{ background: "#d4e0e5" }}>
                                    Result
                                    {item?.isDeclared ? (
                                      <>
                                        {item?.result == "loss" ? (
                                          <strong
                                            className="d-block"
                                            style={{ color: "red" }}
                                          >
                                            {startCase(item?.result)}
                                          </strong>
                                        ) : (
                                          <strong
                                            className="d-block"
                                            style={{ color: "green" }}
                                          >
                                            {startCase(item?.result)}
                                          </strong>
                                        )}
                                      </>
                                    ) : (
                                      <strong className="d-block">
                                        Pending
                                      </strong>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          );
                        })}
                      </>
                    )}
                  </div>

                  <button
                    className={
                      showBet == res?._id
                        ? "show-hide-bet arrow-up"
                        : "show-hide-bet"
                    }
                    onClick={() => handleShowBet(res?._id)}
                  >
                    <FaAngleDown />
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="p-3">
            {object[tab]?.length > 0 ? (
              object[tab]?.map((item, index) => {
                return (
                  <div className="all-bets position-relative" key={index}>
                    <div
                      className={
                        showBet
                          ? "current-bets-table mt-3 show"
                          : "current-bets-table mt-3"
                      }
                    >
                      <Table
                        bordered
                        className="rounded overflow-hidden bg-white mb-0 odds-table"
                      >
                        <thead>
                          <tr>
                            <th colSpan="4">
                              {objConstant?.betCheckObj[item?.eventType]}
                              <BiSolidRightArrow className="arrow-icon" />
                              <span className="master-pass">
                                {item?.matchName}
                              </span>
                              <BiSolidRightArrow className="arrow-icon" />
                              <span className="master-pass">
                                {tab == "exchange"
                                  ? "Match Odds"
                                  : startCase(tab)}
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="lay-back">
                            <td colSpan="3">
                              {" "}
                              <span
                                className={`bet-name ${
                                  item?.type == "No"
                                    ? "lay"
                                    : item?.type == "Yes"
                                    ? "back"
                                    : item?.betType
                                }`}
                              >
                                {startCase(item?.betType || item?.type)}{" "}
                              </span>{" "}
                              {item?.teamName || item?.fancyName}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Odds req.{" "}
                              <strong className="d-block">
                                {!isEmpty(item?.betType)
                                  ? item?.bhav
                                  : `${item?.betRun}/${item?.bhav}`}
                              </strong>
                            </td>
                            <td>
                              Avg. Odds{" "}
                              <strong className="d-block">
                                {!isEmpty(item?.betType)
                                  ? item?.bhav
                                  : `${item?.betRun}/${item?.bhav}`}
                              </strong>
                            </td>
                            <td>
                              Matched (INR)
                              <strong className="d-block">
                                {item?.amount}
                              </strong>
                            </td>
                          </tr>
                          {!isEmpty(item?.betType) && (
                            <tr>
                              <td>Bet ID</td>
                              <td colSpan="2">{item?.matchBetId}</td>
                            </tr>
                          )}
                          <tr>
                            <td>Bet Placed</td>
                            <td colSpan="2">
                              {obj.dateFormat(item?.timeInserted)}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Matched Date</td>
                            <td colSpan="2">2023-06-02 17:04:50</td>
                          </tr> */}
                        </tbody>
                      </Table>

                      {showBet == item?._id && (
                        <Table className="bets-table mb-0">
                          <thead>
                            <th className="pe-0">Bet Taken</th>
                            <th colSpan="2">
                              {obj.dateFormat(item?.timeInserted)}
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Odds req.{" "}
                                <strong className="d-block">
                                  {!isEmpty(item?.betType)
                                    ? item?.bhav
                                    : `${item?.betRun}/${item?.bhav}`}
                                </strong>
                              </td>
                              <td>
                                Avg. Odds{" "}
                                <strong className="d-block">
                                  {!isEmpty(item?.betType)
                                    ? item?.bhav
                                    : `${item?.betRun}/${item?.bhav}`}
                                </strong>
                              </td>
                              <td>
                                Matched (INR)
                                <strong className="d-block">
                                  {item?.amount}
                                </strong>
                              </td>
                            </tr>
                            {(item?.betType == "lay" || item?.type == "No") && (
                              <tr>
                                <td>Liability (INR)</td>
                                <td colSpan="2" className="text-danger">
                                  ({item?.loseAmount})
                                </td>
                              </tr>
                            )}
                            {(item?.betType == "back" ||
                              item?.type == "Yes") && (
                              <tr>
                                <td>Profit (INR)</td>
                                <td colSpan="2" className="text-success">
                                  {item?.profitAmount}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      )}
                    </div>

                    <button
                      className={
                        showBet == item?._id
                          ? "show-hide-bet arrow-up"
                          : "show-hide-bet"
                      }
                      onClick={() => handleShowBet(item?._id)}
                    >
                      <FaAngleDown />
                    </button>
                  </div>
                );
              })
            ) : (
              <NoEvent />
            )}
          </div>
        )}
      </div>
    </SidebarLayout>
  );
};

export default CurrentBets;
