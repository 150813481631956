import React, { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
const BackLay = ({
  component,
  handelChange,
  odds_lay,
  odds_back,
  index,
  item,
  limit,
  betPlaceAccept,
  title
}) => {
  const { betSlipObject, showBetDialog } = useContext(BetSlipContext);
  let backClass = `back ${
    Number(
      Number(document
        .getElementById("back_odds_" + item?.SelectionId)
        ?.title?.split("-")[0]
        ?.trim())
    ) !== Number(odds_back[0]?.rt) ||
    Number(
      Number(document
        .getElementById("back_odds_" + item?.SelectionId)
        ?.title?.split("-")[1]
        ?.trim())
    ) !== Number(odds_back[0]?.bv)
      ? "spark-back"
      : ""
  } ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_back[0]?.rt &&
    showBetDialog && betSlipObject?.betType == 'back'
      ? "back-active"
      : ""
  }`;
  let layClass = `lay ${
    Number(
      Number(document
        .getElementById("lay_odds_" + item?.SelectionId)
        ?.title?.split("-")[0]
        ?.trim())
    ) !== Number(odds_lay[0]?.rt) ||
    Number(
      Number(document
        .getElementById("lay_odds_" + item?.SelectionId)
        ?.title?.split("-")[1]
        ?.trim())
    ) !== Number(odds_lay[0]?.bv)
      ? "spark-lay"
      : ""
  } ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_lay[0]?.rt &&
    showBetDialog && betSlipObject?.betType == 'lay'
      ? "lay-active"
      : ""
  }`;

  let check = (odds) => {
    if (betPlaceAccept) {
      if (Number(odds) > 0) {
        if (Number(odds) > limit?.oddsLimit) {
          return "single-disabled";
        } else {
          return "";
        }
      } else {
        return "single-disabled";
      }
    } else {
      return "single-disabled";
    }
  };

  let checkDisabled = (odds) => {
    if (Number(odds) > 0) {
      if (Number(odds) > limit?.oddsLimit) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  let backCheck = check(odds_back[0]?.rt) || "";
  let layCheck = check(odds_lay[0]?.rt) || "";

  return (
    <div
      className={`lay-back-table ${
        component == "bookmaker" ? "bg-bookmaker-market" : ""
      }`}
    >
      <div
        title={`${Number(odds_back[0]?.rt) > 0 ? odds_back[0]?.rt : ""}-${
          Number(odds_back[0]?.bv) > 0 ? odds_back[0]?.bv : ""
        }`}
        id={"back_odds_" + item?.SelectionId}
        onClick={() => {
          if (betPlaceAccept) {
            if (!checkDisabled(odds_back[0]?.rt)) {
              handelChange(odds_back[0], "back", item?.RunnerName, item, index,title);
            }
          }
        }}
        className={backClass}
      >
        <span className={backCheck}>
          {backCheck == "single-disabled" && <span className="disabled"></span>}
          <strong>{odds_back[0]?.rt}</strong>
          {component == "bookmaker" ? (
            ""
          ) : (
            <span className="d-block">{odds_back[0]?.bv}</span>
          )}
        </span>
      </div>
      <div
        title={`${Number(odds_lay[0]?.rt) > 0 ? odds_lay[0]?.rt : ""}-${
          Number(odds_lay[0]?.bv) > 0 ? odds_lay[0]?.bv : ""
        }`}
        id={"lay_odds_" + item?.SelectionId}
        onClick={() => {
          if (betPlaceAccept) {
            if (!checkDisabled(odds_lay[0]?.rt)) {
              handelChange(odds_lay[0], "lay", item?.RunnerName, item, index,title);
            }
          }
        }}
        className={layClass}
      >
        <span className={layCheck}>
          {layCheck == "single-disabled" && <span className="disabled"></span>}
          <strong>{odds_lay[0]?.rt}</strong>
          {component == "bookmaker" ? (
            ""
          ) : (
            <span className="d-block">{odds_lay[0]?.bv}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default BackLay;
