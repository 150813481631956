import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import moment from "moment";
import { apiPost } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import { BsMenuButton } from "react-icons/bs";
import { omit, isEmpty, inRange } from "lodash";
import BetSlipContext from "../../context/BetSlipContext";
const Lottery = ({ parmas }) => {
  let array = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    ...Array.from(Array(100).keys()).slice(10, 100),
  ];
  let minSingle = {
    min: 100,
    max: 100000,
    maxProfit: 1000000,
  };
  let minDouble = {
    min: 10,
    max: 10000,
    maxProfit: 2500000,
  };
  const { setMessage, message } = useContext(BetSlipContext);
  const [limit, setLimit] = useState(minSingle);
  let arraySingle = Array.from(Array(10).keys());
  let { setMessageLottery, userCoins,setRefreshAmountLoader } = useContext(AuthContext);
  const numberArray = [100, 200, 500, 1000, 2000, 5000, 10000];
  const [bid, setBid] = useState(0);
  const [show, setShow] = useState(false);
  const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00", "img"];
  const [active, setActive] = useState({});
  const [header, setHeader] = useState("single");
  const [undoArray, setUndoArray] = useState([]);
  const handelDialPad = (item) => {
    if (item == "img") {
      if (bid > 0) {
        let str = bid?.toString();
        setBid(Number(str.substring(0, str.length - 1)));
      }
    } else {
      if (item == 0) {
        if (bid > 0) {
          setBid((prev) => Number(`${prev}${item}`));
        }
      } else {
        setBid((prev) => prev + item);
      }
    }
  };

  const handelNumberChange = (item) => {
    setBid((prev) => Number(prev) + Number(item));
  };

  const selectNum = (num, bid, limit) => {
    if (inRange(bid, limit?.min, limit?.max)) {
      setActive((prev) => {
        setUndoArray((item) => {
          return [...item, { [num]: Number(bid) }];
        });
        return {
          ...prev,
          [num]: Number(prev[num] || 0) + Number(bid),
        };
      });
    } else {
      setMessageLottery({
        status: true,
        message: `Please Select Amount from ${limit?.min} - ${limit?.max}`,
        errorType: "error",
      });
    }
  };

  const placebet = async (bid, limit) => {
    let totalAmount = Object.values(active).reduce((prev, acc) => {
      return Number(prev) + Number(acc);
    }, 0);
    if (totalAmount > userCoins?.balance) {
      setMessage({
        ...message,
        status: true,
        message: "You do not have a sufficient amount",
        errorType: "Error",
      });
    } else {
      if (inRange(bid, limit?.min, limit?.max) && !isEmpty(active)) {
        const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
        let result = {
          eventId: parmas?.eventId,
          marketId: parmas?.marketId,
          betPlaceTime: date,
          betType: "lottery",
          betArry: Object.entries(active)?.map((res) => {
            return { [res[0]]: res[1] };
          }),
          lotteryType: header,
          bid: Object.values(active).reduce((prev, acc) => {
            return Number(prev) + Number(acc);
          }, 0),
        };
        const { status, data: response_users } = await apiPost(
          apiPath.lotteryPlaceBet,
          result
        );
        if (response_users?.success) {
          setActive({});
          setRefreshAmountLoader(true)
          setUndoArray([]);
          setBid(0);
          setMessage({
            ...message,
            status: true,
            message: "Bet Placed Successfully",
            errorType: "P2P-Success",
          });
        } else {
          setMessage({
            ...message,
            status: true,
            message: response_users?.message,
            errorType: "Error",
          });
        }
      }
    }
  };

  const handelUndo = (undoArray) => {
    if (undoArray?.length > 0) {
      setActive((prev) => {
        let undoKey = Object.keys(undoArray[undoArray?.length - 1])[0];
        let undoValue = Object.values(undoArray[undoArray?.length - 1])[0];
        setUndoArray((prev) => {
          prev.pop();
          return prev;
        });
        if (Number(prev[undoKey]) - Number(undoValue) == 0) {
          return omit(prev, [undoKey]);
        } else {
          return {
            ...prev,
            [undoKey]: Number(prev[undoKey]) - Number(undoValue),
          };
        }
      });
    }
  };

  return (
    <div className="lottery">
      <div className="lottery-main">
        <div className="w-100 d-flex align-items-center lottery-header-outer">
          <div
            style={{ marginRight: "10px" }}
            onClick={() => {
              setHeader("single");
              setActive({});
              setUndoArray([]);
              setLimit(minSingle);
            }}
          >
            <h6 className={header == "single" ? "active" : ""}>Single Digit</h6>
          </div>
          <div
            onClick={() => {
              setHeader("double");
              setActive({});
              setUndoArray([]);
              setLimit(minDouble);
            }}
          >
            <h6 className={header == "double" ? "active" : ""}>Double Digit</h6>
          </div>
        </div>
        {header == "single" ? (
          <div className="lottery-header">
            <span>Last over single digit lottery</span>
            <span>
              1 INR Get <span className="inr">9 INR</span>{" "}
              <span>All Match</span>
            </span>{" "}
          </div>
        ) : (
          <div className="lottery-header">
            <span>Last over double digit lottery</span>
            <span>
              1 INR Get <span className="inr">50 INR</span>{" "}
              <span>T20 Match</span>
            </span>{" "}
            {/* <span>
              1 INR Get <span className="inr">60 INR</span>
              <span>50-50 Match</span>
            </span>{" "}
            <span>
              1 INR Get <span className="inr">70 INR</span>
              <span>Test Match</span>
            </span> */}
          </div>
        )}
        <div className="lottery-map w-100">
          {(header == "single" ? arraySingle : array)?.map((res) => {
            return (
              <div onClick={() => selectNum(res, bid, limit)}>
                {" "}
                {active[res] && (
                  <span className="active-lottery">{active[res]}</span>
                )}
                {res}
              </div>
            );
          })}
        </div>
        <Row xs={12} className="w-100 m-0 p-0 mt-2">
          <Col xs={6} className="">
            <span
              className="d-flex align-item-center text-white h-100"
              style={{ fontWeight: "600" }}
            >
              Bet Amount :{" "}
              {!isEmpty(active)
                ? Object.values(active).reduce((prev, acc) => {
                    return Number(prev) + Number(acc);
                  }, 0)
                : 0}
            </span>
          </Col>
          <Col xs={6}>
            <input
              type="number"
              inputmode="none"
              className="lottery-input"
              value={bid}
              onClick={(e) => {
                e.target.blur();
                setShow(true);
              }}
              maxLength={7}
            />
          </Col>
        </Row>
        <div className="player-body w-100 mb-3">
          <Form className="border-0">
            <div className="fix-bet-price d-flex align-items-center mt-2">
              <div className="fix-bet-btn d-flex align-items-center w-100 flex-wrap">
                {numberArray?.map((item) => {
                  return (
                    <Button
                      onClick={() => {
                        handelNumberChange(item);
                      }}
                      className="bg-yellow-3 btn mb-1"
                    >
                      {item}
                    </Button>
                  );
                })}
              </div>
            </div>
            {undoArray?.length > 0 && (
              <div className="fix-bet-price d-flex align-items-center mt-2">
                <div className="fix-bet-btn d-flex align-items-center w-100 flex-wrap">
                  <Button
                    style={{
                      background: "#735a08",
                      border: "none",
                      width: "48%",
                    }}
                    onClick={() => handelUndo(undoArray)}
                  >
                    Undo
                  </Button>{" "}
                  <Button
                    style={{
                      background: "#735a08",
                      border: "none",
                      width: "48%",
                    }}
                    onClick={() => {
                      setActive({});
                      setUndoArray([]);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            )}
            {show && (
              <div className="betting-numbers betting-numbers-2 d-flex align-items-center mb-2">
                <div className="d-flex flex-wrap bet-numeric">
                  {numberArrayCount?.map((res) => {
                    return res == "img" ? (
                      <Button
                        onClick={() => {
                          handelDialPad(res);
                          // setActive([]);
                        }}
                        className="btn"
                      >
                        <img
                          src="../../assets/images/casino-icon/cross.png"
                          alt=""
                        />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handelDialPad(res)}
                        className="btn"
                      >
                        {res}
                      </Button>
                    );
                  })}
                </div>
                <div className="cross-bet">
                  <Button
                    onClick={() => {
                      setShow(false);
                    }}
                    style={{ fontWeight: "600" }}
                    className="btn h-100"
                  >
                    OK
                  </Button>
                </div>
              </div>
            )}
            <div className="w-100 d-flex justify-content-end">
              <span
                style={{ fontSize: "12px", color: "white", fontWeight: "600" }}
              >
                min : {limit?.min} / max : {limit?.max}
              </span>
            </div>

            <Button
              onClick={() => placebet(bid, limit)}
              disabled={
                inRange(bid, limit?.min, limit?.max) && !isEmpty(active)
                  ? false
                  : true
              }
              className="bg-yellow-3 w-100 fs-5 py-2 fw-bold place-bet-button mt-2"
            >
              Place Bet
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Lottery;
