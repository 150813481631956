import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import AuthContext from "../context/AuthContext";
import { isEmpty, isNumber } from "lodash";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import BetSlipContext from "../context/BetSlipContext";
const StackSetting = ({ handleStackSetting }) => {
  const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, "img"];
  const { profileData, getProfileData } = useContext(AuthContext);
  const { betSlipObject } = useContext(BetSlipContext);
  const [focus, setFocus] = useState("");

  // profileData?.stake?.slice(0, 4)?.length > 0
  // ? profileData?.stake?.slice(0, 4)
  // : profileData?.editStake?.slice(0, 4)

  const [stake, setStake] = useState([]);
  useEffect(() => {
    let array =
      betSlipObject?.playType == "bookmaker" ||
      betSlipObject?.playType == "fancy"
        ? profileData?.stake?.length > 0
          ? profileData?.stake?.length == 4
            ? [...profileData?.stake, ...profileData?.editStake.slice(4, 8)]
            : profileData?.stake
          : profileData?.editStake
        : (profileData?.stake?.length > 0
            ? profileData?.stake
            : profileData?.editStake
          )?.slice(0, 4);
    setStake(array);
  }, []);

  const handelDialPad = (item) => {
    if (isNumber(focus)) {
      setStake((prev) => {
        return prev?.map((res, index) => {
          if (index == focus) {
            return Number(res.toString() + item.toString());
          } else {
            return res;
          }
        });
      });
    }
  };

  const clear = () => {
    setStake((prev) => {
      return prev.map((res, index) => {
        if (index == focus && res?.toString()?.length > 0) {
          return Number(res.toString().substring(0, res.toString().length - 1));
        } else {
          return res;
        }
      });
    });
  };

  const submit = async () => {
    const { status, data: response_users } = await apiPost(
      apiPath.userProfileUpdate,
      {
        stake: stake,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        getProfileData();
        handleStackSetting();
      }
    }
  };
  return (
    <div className="market-depth-modal default-stake">
      <div className="market-title">
        <h4>Default stake setting</h4>
        <Button
          className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
          onClick={() => handleStackSetting()}
        >
          <RxCross2 />
        </Button>
      </div>
      <div className="market-depth-body">
        <Form>
          <div className="bg-darklight p-2">
            <Row className="gx-3">
              {stake?.map((res, index) => {
                return (
                  <Col xs={3}>
                    <Form.Group>
                      <Form.Control
                        type="number"
                        inputmode="none"
                        onClick={(e) => {
                          e.target.blur();
                          setFocus(index);
                        }}
                        value={res}
                        maxLength={6}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </Form.Group>
                  </Col>
                );
              })}
            </Row>
          </div>

          <div className="betting-numbers  mb-2 px-sm-3 px-2">
            <div className="d-flex flex-wrap bet-numeric w-100 mb-sm-4 mb-2">
              {numberArrayCount?.map((res, index) => {
                if (res == "img") {
                  return (
                    <Button
                      onClick={() => {
                        clear();
                      }}
                      className="btn cross-bet"
                    >
                      <img
                        src="../../assets/images/casino-icon/cross.png"
                        alt=""
                      />
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      onClick={() => {
                        if (isNumber(res)) {
                          handelDialPad(res);
                        }
                      }}
                      className="btn"
                    >
                      {res}
                    </Button>
                  );
                }
              })}
            </div>
            <Button
              onClick={submit}
              className="bg-yellow w-100 fs-5 py-2 fw-bold"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default StackSetting;
