import React, { useContext, useEffect, useState } from "react";
import SportsTab from "./CurrentBets/SportsTab";
import SidebarLayout from "../components/shared/SidebarLayout";
import { apiGet } from "../Utils/apiFetch";
import { FaAngleDown } from "react-icons/fa";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Table,
  Container,
} from "react-bootstrap";
import apiPath from "../Utils/apiPath";
import objConstant from "../Utils/constants";
import { isEmpty, startCase } from "lodash";
import obj from "../Utils/helpers";
import { BiSolidRightArrow } from "react-icons/bi";
import NoEvent from "../components/NoEvent";
import BetSlipContext from "../context/BetSlipContext";
import AuthContext from "../context/AuthContext";
import moment from "moment";
import { AiTwotoneCalendar } from "react-icons/ai";
const BetsHistory = () => {
  const [tab, setTab] = useState("exchange");
  const [data, setData] = useState([]);
  const { setMessage, message } = useContext(BetSlipContext);
  const { setShowDate, plDate, isChangeDate, setIsChangeDate } =
    useContext(AuthContext);
  const [showBet, setShowBet] = useState("");
  const [filter, setFilter] = useState({
    betType: "betfair",
    status: "completed",
    fromPeriod: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    toPeriod: moment(new Date()).format("YYYY-MM-DD"),
    displayStart: moment(new Date()).subtract(7, "days").format("DD/MM/YYYY"),
    displayEnd: moment(new Date()).format("DD/MM/YYYY"),
    page: 1,
    limit: 50,
  });

  const handleShowBet = (data) => {
    if (data == showBet) {
      setShowBet("");
    } else {
      setShowBet(data);
    }
  };

  const handel = (value) => {
    setTab(value);
    setFilter({
      ...filter,
      betType:
        value == "exchange"
          ? "betfair"
          : value == "sportsBook"
          ? "sportBook"
          : value,
    });
    getData({
      ...filter,
      betType:
        value == "exchange"
          ? "betfair"
          : value == "sportsBook"
          ? "sportBook"
          : value,
    });
  };

  const getData = async (obj = filter) => {
    const { status, data } = await apiGet(apiPath.getHistory, {
      betType: obj?.betType,
      status: obj?.status,
      fromPeriod: obj?.fromPeriod,
      toPeriod: obj?.toPeriod,
      page: obj?.page || 1,
      limit: obj?.limit,
    });
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isChangeDate) {
      setFilter({
        betType: filter?.betType,
        status: filter?.status,
        fromPeriod:
          plDate[0]?.startDate !== ""
            ? moment(plDate[0]?.startDate).format("YYYY-MM-DD")
            : "",
        toPeriod:
          plDate[0]?.endDate !== ""
            ? moment(plDate[0]?.endDate).format("YYYY-MM-DD")
            : "",
        page: 1,
        limit: 50,
        displayStart:
          plDate[0]?.startDate !== ""
            ? moment(plDate[0]?.startDate).format("DD/MM/YYYY")
            : "",
        displayEnd:
          plDate[0]?.endDate !== ""
            ? moment(plDate[0]?.endDate).format("DD/MM/YYYY")
            : "",
      });
      setIsChangeDate(false);
    }
  }, [plDate, isChangeDate]);

  const getResult = async (obj) => {
    const { status, data } = await apiGet(apiPath.casinoGetResult, {
      userId: obj?.clientName,
      platform: obj?.platform,
      platformTxId: obj?.platformTxId,
    });
    if (status == 200) {
      if (data?.success) {
        setMessage({
          ...message,
          status: true,
          errorType: "P2P-Success",
          message: data?.message,
        });
      } else {
        setMessage({
          ...message,
          status: true,
          errorType: "Error",
          message: data?.message,
        });
      }
    }
  };

  return (
    <SidebarLayout heading={"Bets History"}>
      <div className="pt-0 inner-sidebar-content">
        <SportsTab setTab={handel} tab={tab} />
        <div className="pl-filter">
          <div className="select-container bet-history-dropdown">
            {filter?.betType !== 'lottery' &&
            <DropdownButton
              className="bet-status-dropdown"
              id="dropdown-basic-button"
              title={`Bet Status ${
                filter?.status == "completed"
                  ? "Completed"
                  : startCase(filter?.status)
              }`}
            >
              <Dropdown.Item
                onClick={() => {
                  setFilter({ ...filter, status: "completed" });
                  getData({ ...filter, status: "completed" });
                }}
              >
                Completed
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilter({ ...filter, status: "cancelled" });
                  getData({ ...filter, status: "cancelled" });
                }}
              >
                Cancelled
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilter({ ...filter, status: "voided" });
                  getData({ ...filter, status: "voided" });
                }}
              >
                Voided
              </Dropdown.Item>
            </DropdownButton>}
            <div className="pl-filter-first p-0 mt-2">
              <div
                onClick={() => setShowDate({ status: true, type: "Calendar" })}
                className="pl-filter-first-time"
              >
                <AiTwotoneCalendar />
                <span className="text-white truncate">
                  {filter?.displayStart} - {filter?.displayEnd}
                </span>
              </div>
              <Button
                onClick={() => setShowDate({ status: true, type: "During" })}
              >
                ...
              </Button>
              <Button onClick={() => getData(filter)}>Submit</Button>
            </div>
          </div>
        </div>
        <Container>
          {tab == "lottery" ? (
            <div className="p-1">
            {data?.length > 0 ? (
              data?.map((item, index) => {
                let backprofit = item?.bets
                  ?.filter((obj) => {
                    return obj?.decision === obj?.selectionId;
                  })
                  .reduce((a, v) => {
                    return parseFloat(a) + parseFloat(v.profitAmount);
                  }, 0);

                let backLoss = item?.bets
                  ?.filter((obj) => {
                    return obj?.decision !== obj?.selectionId;
                  })
                  .reduce((a, v) => {
                    return parseFloat(a) + parseFloat(v.loseAmount);
                  }, 0);
                let total = backprofit - backLoss;
                console.log(total, "total");
                if (item?.bets?.length > 0) {
                  return (
                    <div className="all-bets position-relative" key={index}>
                      <div
                        className={
                          showBet
                            ? "current-bets-table mt-3 show"
                            : "current-bets-table mt-3"
                        }
                      >
                        <Table
                          bordered
                          className="rounded overflow-hidden bg-white mb-0 odds-table"
                        >
                          <thead>
                            <tr>
                              <th colSpan="4">
                                Bet Placed
                                <BiSolidRightArrow className="arrow-icon" />
                                <span className="master-pass">
                                  {obj.dateFormat(item?.timeInserted)}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="lay-back">
                              {!item?.isDeclared ? (
                                <td colSpan="3">
                                  {" "}
                                  <span
                                    style={{ background: "#d4e0e5" }}
                                    className={`bet-name`}
                                  >
                                    {`${startCase(item?.lotteryName)} Digit`}
                                  </span>
                                  {item?.bets[0]?.matchName}
                                </td>
                              ) : (
                                <td colSpan="3">
                                  {" "}
                                  <span
                                    style={
                                      item?.result == "win"
                                        ? {
                                            background: "green",
                                            color: "white",
                                          }
                                        : item?.result == "loss"
                                        ? { background: "red", color: "white" }
                                        : { background: "#d4e0e5" }
                                    }
                                    className={`bet-name`}
                                  >
                                    {`${startCase(item?.lotteryName)} Digit`}
                                  </span>
                                  {item?.bets[0]?.matchName}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td>
                                Bhav
                                <strong className="d-block">
                                  {item?.bhav}
                                </strong>
                              </td>{" "}
                              <td>
                                Profit / Loss
                                <strong
                                  className={`d-block ${
                                    total > 0 ? "text-success" : "text-danger"
                                  }`}
                                >
                                  {total}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        {showBet == item?._id &&
                          item?.bets?.length > 0 &&
                          item?.bets?.map((res) => {
                            return (
                              <>
                                <Table className="bets-table  mb-0">
                                  <thead>
                                    <th colSpan="4">
                                      {/* <span
                                        style={{ background: "#9cb1bd" }}
                                        className=""
                                      >
                                        Selection : {res?.runnerName}
                                      </span> */}

                                      <span
                                        style={{ background: "#9cb1bd" }}
                                        className=""
                                      >
                                        Bet Amount :{" "}
                                        {parseFloat(res?.amount).toFixed(2)}
                                      </span>
                                    </th>
                                  </thead>
                                  <tbody style={{ background: "#d4e0e5" }}>
                                    <tr>
                                      {" "}
                                      <td
                                        style={{
                                          background: "#d4e0e5",
                                          borderBottom: "1px solid #9cb1bd",
                                        }}
                                        colSpan="3"
                                      >
                                        {res?.fancyName}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          background: "#d4e0e5",
                                          borderRight: "1px solid #9cb1bd",
                                        }}
                                      >
                                        Selection
                                        <strong className="d-block">
                                          {res?.selectionId}
                                        </strong>
                                      </td>{" "}
                                      <td
                                        style={{
                                          background: "#d4e0e5",
                                          borderRight: "1px solid #9cb1bd",
                                        }}
                                      >
                                        Result
                                        <strong className="d-block">
                                          {res?.decision}
                                        </strong>
                                      </td>
                                      <td
                                        style={{
                                          background: "#d4e0e5",
                                          borderRight: "1px solid #9cb1bd",
                                        }}
                                      >
                                        Profit / Loss
                                        {res?.isDeclared ? (
                                          <strong
                                            className={`d-block ${
                                              res?.decision === res?.selectionId
                                                ? "text-success"
                                                : "text-danger"
                                            }`}
                                          >
                                            {res?.decision === res?.selectionId
                                              ? res?.profitAmount
                                              : -res?.loseAmount}
                                          </strong>
                                        ) : (
                                          <strong className="d-block">
                                            Pending
                                          </strong>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </>
                            );
                          })}
                        {/* {showBet == item?._id && (
                          <div className="profit-loss-footer">
                            <div className="profit-loss-footer-inner-footer">
                              <span className="text">Net Market Total</span>
                              {item?.result == "win" ? (
                                <span className="text-success">
                                  {Math.abs(
                                    obj.truncateDecimals(item?.profitAmount, 2)
                                  )}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  (
                                  {Math.abs(
                                    obj.truncateDecimals(item?.loseAmount, 2)
                                  )}
                                  )
                                </span>
                              )}
                            </div>
                          </div>
                        )} */}
                      </div>

                      <button
                        className={
                          showBet == item?._id
                            ? "show-hide-bet arrow-up"
                            : "show-hide-bet"
                        }
                        onClick={() => handleShowBet(item?._id)}
                      >
                        <FaAngleDown />
                      </button>
                    </div>
                  );
                }
              })
            ) : (
              <NoEvent />
            )}
          </div>
          ) : (
            <div className="p-md-3 p-0">
              {data?.length > 0 ? (
                data?.map((item, index) => {
                  return (
                    <div className="all-bets position-relative" key={index}>
                      <div
                        className={
                          showBet
                            ? "current-bets-table mt-3 show"
                            : "current-bets-table mt-3"
                        }
                      >
                        <Table
                          bordered
                          className="rounded overflow-hidden bg-white mb-0 odds-table"
                        >
                          <thead>
                            <tr>
                              <th colSpan="4">
                                {objConstant?.betCheckObj[item?.eventType]}
                                <BiSolidRightArrow className="arrow-icon" />
                                <span className="master-pass">
                                  {item?.matchName || item?.casinoName}
                                </span>
                                <BiSolidRightArrow className="arrow-icon" />
                                <span className="master-pass">
                                  {tab == "exchange"
                                    ? "Match Odds"
                                    : startCase(tab)}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="lay-back">
                              <td colSpan="3">
                                {" "}
                                {filter?.betType == "casino" ? (
                                  <span>Bet Id -</span>
                                ) : (
                                  <span
                                    className={`bet-name ${
                                      item?.type == "No"
                                        ? "lay"
                                        : item?.type == "Yes"
                                        ? "back"
                                        : item?.betType
                                    }`}
                                  >
                                    {startCase(item?.betType || item?.type)}{" "}
                                  </span>
                                )}
                                {filter?.betType == "casino"
                                  ? item?.platformTxId
                                  : item?.teamName || item?.fancyName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Odds req.{" "}
                                {filter?.betType !== "casino" ? (
                                  <strong className="d-block">
                                    {!isEmpty(item?.betType)
                                      ? item?.bhav
                                      : `${item?.betRun}/${item?.bhav}`}
                                  </strong>
                                ) : (
                                  <strong className="d-block">0.0</strong>
                                )}
                              </td>
                              <td>
                                Avg. Odds{" "}
                                {filter?.betType !== "casino" ? (
                                  <strong className="d-block">
                                    {!isEmpty(item?.betType)
                                      ? item?.bhav
                                      : `${item?.betRun}/${item?.bhav}`}
                                  </strong>
                                ) : (
                                  <strong className="d-block">0.0</strong>
                                )}
                              </td>
                              <td>
                                {filter?.betType == "casino"
                                  ? "Stake"
                                  : "Matched (PKU)"}
                                <strong className="d-block">
                                  {item?.amount || item?.betAmount}
                                </strong>
                              </td>
                            </tr>
                            {!isEmpty(item?.betType) && (
                              <tr>
                                <td>Bet ID</td>
                                <td colSpan="2">{item?.matchBetId}</td>
                              </tr>
                            )}
                            {filter?.betType !== "casino" && (
                              <tr>
                                <td>Bet Placed</td>
                                <td colSpan="2">
                                  {obj.dateFormat(item?.timeInserted)}
                                </td>
                              </tr>
                            )}
                            {/* <tr>
                            <td>Matched Date</td>
                            <td colSpan="2">2023-06-02 17:04:50</td>
                          </tr> */}
                          </tbody>
                        </Table>

                        {showBet == item?._id && (
                          <Table className="bets-table mb-0">
                            <thead>
                              <th className="pe-0">Bet Taken</th>
                              <th colSpan="2">
                                {obj.dateFormat(item?.timeInserted)}
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                {filter?.betType !== "casino" && (
                                  <>
                                    <td>
                                      Odds req.{" "}
                                      <strong className="d-block">
                                        {!isEmpty(item?.betType)
                                          ? item?.bhav
                                          : `${item?.betRun}/${item?.bhav}`}
                                      </strong>
                                    </td>
                                    <td>
                                      Avg. Odds{" "}
                                      <strong className="d-block">
                                        {!isEmpty(item?.betType)
                                          ? item?.bhav
                                          : `${item?.betRun}/${item?.bhav}`}
                                      </strong>
                                    </td>
                                  </>
                                )}
                                <td>
                                  {filter?.betType == "casino"
                                    ? "Stake"
                                    : "Matched (PKU)"}
                                  <strong className="d-block">
                                    {item?.amount || item?.betAmount}
                                  </strong>
                                </td>
                                {filter?.betType == "casino" && (
                                  <>
                                    <td>
                                      Profit / Loss
                                      <strong
                                        className={`d-block ${
                                          item?.playerPL > 0
                                            ? ""
                                            : "text-danger"
                                        }`}
                                      >
                                        {item?.playerPL > 0
                                          ? item?.playerPL
                                          : `(${item?.playerPL})`}
                                      </strong>
                                    </td>
                                    {/* <td>
                                    <Button onClick={() => getResult(item)}>
                                      Get Result
                                   </Button>
                                 </td>*/}
                                  </>
                                )}
                              </tr>
                              {item?.betType == "lay" && (
                                <tr>
                                  <td>Liability (PKU)</td>
                                  <td colSpan="2" className="text-danger">
                                    ({item?.loseAmount})
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        )}
                      </div>

                      <button
                        className={
                          showBet == item?._id
                            ? "show-hide-bet arrow-up"
                            : "show-hide-bet"
                        }
                        onClick={() => handleShowBet(item?._id)}
                      >
                        <FaAngleDown />
                      </button>
                    </div>
                  );
                })
              ) : (
                <NoEvent />
              )}
            </div>
          )}
        </Container>
      </div>
    </SidebarLayout>
  );
};

export default BetsHistory;
