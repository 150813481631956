import React from "react";
import { GiSoccerBall, GiTennisBall } from "react-icons/gi";
import { AiFillTrophy } from "react-icons/ai";
import { useLocation } from "react-router-dom";

const GameMenu = ({ game, setGame, type }) => {
  const location = useLocation();

  return (
    <div className={location?.pathname === "/sports" ? "game-menu sport-menu": "game-menu"}>
      <ul className="p-0 mb-0">
          <li
            onClick={() => setGame("all")}
            className={game === "all" ? "active" : ""}
          >
            <div>
              <AiFillTrophy />
            </div>
            All
          </li>
        <li
          onClick={() => setGame("cricket")}
          className={game === "cricket" ? "active" : ""}
        >
          <div>
            <GiSoccerBall />
          </div>
          Cricket
        </li>
        <li
          onClick={() => setGame("soccer")}
          className={game === "soccer" ? "active" : ""}
        >
          <div>
            <GiSoccerBall />
          </div>
          Soccer
        </li>
        <li
          onClick={() => setGame("tennis")}
          className={game === "tennis" ? "active" : ""}
        >
          <div>
            <GiTennisBall />
          </div>
          Tennis
        </li>
 
      </ul>
    </div>
  );
};

export default GameMenu;
