import React, { useContext, useRef, useState } from "react";
import { BsTelegram, BsFacebook } from "react-icons/bs";
import { RiWhatsappFill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FooterIcon from "../assets/images/download-apk.png";
import CasinoPopup from "./CasinoPopup";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import Draggable from "react-draggable";
const Footer = () => {
  const location = useLocation();
  const { user,showSidebar } = useContext(AuthContext);
  const navigate = useNavigate();
  const [casinoModel, setCasinoModel] = useState(false);
  const ref = useRef(null);
  return (
    <>
      <div className="footer">
        {location?.pathname == "/" && !showSidebar && (
          <>
            {/* <div
              onClick={() => {
                if (!isEmpty(user)) {
                 
                } else {
                  navigate("/login");
                }
              }}
              className="betbtn1"
            >
              <img src="../../assets/images/entrance.svg" alt="" />
            </div> */}
            <div  ref={ref} onClick={() => console.log('workig outer')}>
            <Draggable
            ref={ref}
              // bounds={{
              //   right: 0,
              //   bottom: 70,
              //   left: 0,
              // }}
              // onClick={(e) => console.log(e, "eee")}
              // onDrag={onDrag}
              // onStop={onStop}
            >
              <div
                onClick={() => {
                  // if (!isEmpty(user)) {
                  console.log("working");
                  setCasinoModel(true);
                  // } else {
                  //   navigate("/login");
                  // }
                }}
                className="betbtn2 d-none"
              >
                <img
                  src="../../assets/images/sabas.png"
                  className="bounce-4"
                  alt=""
                />
              </div>
            </Draggable>
            </div>
          </>
        )}

    
      </div>
      <CasinoPopup casinoModel={casinoModel} setCasinoModel={setCasinoModel} />
    </>
  );
};

export default Footer;
