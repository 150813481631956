import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const SportsCard = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div className="sport-book">
      <div className="casino-racing">
        <Row className="gx-md-3 gx-1">
          <Col xs={6} className="mb-md-3 mb-1">
            <div
              onClick={() => {
                if (isEmpty(user)) {
                  navigate("/login");
                }
              }}
              className="text-center racing-sec d-flex align-items-center justify-content-between"
            >
              {/* <div>
              <h2 className="">Sports Book</h2>
              <strong className="d-block">50+ Sports</strong>
              </div>
              <figure>
                <img src="../assets/images/b1.png" alt=""/>
              </figure> */}
              <img
                style={{ borderRadius: "5px" }}
                src="../assets/images/1.webp"
                alt=""
              />
            </div>
          </Col>

          <Col xs={6} className="mb-md-3 mb-1">
            <div
              onClick={() => {
                if (isEmpty(user)) {
                  navigate("/login");
                }
              }}
              className="text-center racing-sec d-flex align-items-center justify-content-between"
            >
              {/* <div>
              <h2 className="">Sports Exchange</h2>
              <strong className="d-block">100+ Event Everyday</strong>
              </div>
              <figure>
                <img src="../assets/images/b2.png" alt=""/>
              </figure> */}
              <img
                style={{ borderRadius: "5px" }}
                src="../assets/images/2.webp"
                alt=""
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SportsCard;
