import React from "react";
import WifiLoader from "./WifiLoader";

const Loader = () => {
  return (
    <div id="loading" className="loading">
      <WifiLoader/>
    </div>
  );
};

export default Loader;
