import React, { useContext } from "react";
import { Button, Table, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SidebarLayout from "../components/shared/SidebarLayout";

const Profile = () => {
  const navigate = useNavigate();
  let { user } = useContext(AuthContext);
  console.log(user, "user");
  return (
    <SidebarLayout heading={"My Profile"}>
      {" "}
      <Container className="p-sm-2 p-0">
      <div className="p-sm-3 p-2 inner-sidebar-content">
        <Table className="align-middle bg-white">
          <tbody>
            <tr>
              <td width="30%">User Name</td>
              <td width="70%" colSpan="2">
                {user?.user?.username}
              </td>
            </tr>
            <tr>
              <td width="30%">Email</td>
              <td width="70%" colSpan="2">
                {user?.user?.email}
              </td>
            </tr>
            {/* <tr>
          <td width="40%">Contact Number</td>
          <td width="60%" colSpan="2">{user?.user?.firstName}</td>
        </tr> */}
            <tr>
              <td width="30%">Password</td>
              <td width="40%">********</td>
              <td width="30%">
                <Button
                  onClick={() => navigate("/change-password")}
                  className="theme-btn py-1 px-3 fs-6"
                >
                  Edit
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      </Container>
    </SidebarLayout>
  );
};

export default Profile;
