import { FaWallet, FaRegClock, FaRegUser } from "react-icons/fa";
import { BiWalletAlt, Bi, BiTransfer } from "react-icons/bi";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { CgKeyhole } from "react-icons/cg";
import { TbTransferIn } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineAccountBalance, MdOutlineWhatsapp } from "react-icons/md";
import HomeIcon from "../assets/images/home.png";
import casinoIcon from "../assets/images/casino.png";
import sportIcon from "../assets/images/sports.png";
import leadgeIcon from "../assets/images/leadge.png";
import betsIcon from "../assets/images/mybets.png";
import banner1 from "../assets/images/Banner 1.webp";
import banner2 from "../assets/images/Banner 2.webp";
import banner3 from "../assets/images/Banner 3.webp";
import banner4 from "../assets/images/Banner 4.jpeg";
import { RiLuggageDepositLine } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdSupportAgent } from "react-icons/md";
import c1 from "../assets/images/c1.webp";
import c2 from "../assets/images/c2.webp";
import c3 from "../assets/images/c3.webp";
import c4 from "../assets/images/c4.webp";
import c5 from "../assets/images/c5.webp";
import c6 from "../assets/images/c6.webp";
import c7 from "../assets/images/c7.webp";
import c8 from "../assets/images/c8.webp";
import c9 from "../assets/images/c9.webp";
import c10 from "../assets/images/c10.webp";
import c11 from "../assets/images/c11.webp";
import c12 from "../assets/images/c12.webp";
import { IoMdLogIn } from "react-icons/io";

import Cricket from "../assets/images/Cricket.webp";
import Inplay from "../assets/images/InPlay.webp";
import Sports from "../assets/images/Sports.webp";
import Soccer from "../assets/images/Soccer.webp";
import IndianPoker from "../assets/images/IndianPoker.webp";
import Vivo from "../assets/images/Roulette.webp";
import Ezugi from "../assets/images/Ezugi.webp";
import Evoultion from "../assets/images/casino-icon.svg";
import Aviator from "../assets/images/Aviator.webp";
import Casino from "../assets/images/Casino.webp";
export const NavDepositWithrawData = [
  {
    icons: Inplay,
    title: "In Play",
    firstTab: "InPlay",
    SecondTab: "all",
  },
  {
    icons: Cricket,
    title: "Cricket",
    firstTab: "Cricket",
    SecondTab: "cricket",
  },
  {
    icons: Sports,
    title: "Tennis",
    firstTab: "Tennis",
    SecondTab: "tennis",
  },
  {
    icons: Soccer,
    title: "Soccer",
    firstTab: "Soccer",
    SecondTab: "soccer",
  },
  {
    icons: IndianPoker,
    title: "Indian Poker",
    firstTab: "poker",
  },
  {
    icons: Vivo,
    title: "Vivo",
    firstTab: "vivo",
  },
  {
    icons: Ezugi,
    title: "Ezugi",
    firstTab: "ezugi",
  },
  {
    icons: Evoultion,
    title: "Evoultion",
    firstTab: "evolution",
  },
  {
    icons: Aviator,
    title: "Aviator",
    firstTab: "aviator",
  },
  {
    icons: Casino,
    title: "Live",
    firstTab: "live",
  },
];

export const NavDepositWithrawData2 = [
  {
    icons: IndianPoker,
    title: "Indian Poker",
    firstTab: "poker",
  },
  {
    icons: Ezugi,
    title: "Ezugi",
    firstTab: "ezugi",
  },
  {
    icons: Evoultion,
    title: "Evoultion",
    firstTab: "evolution",
  },
  {
    icons: Vivo,
    title: "Vivo",
    firstTab: "vivo",
  },
 
  {
    icons: Aviator,
    title: "Aviator",
    firstTab: "aviator",
  },
  {
    icons: Casino,
    title: "Live",
    firstTab: "live",
  },
];

export const bannerSlider = [
  { id: 1, img: banner1 },
  { id: 2, img: banner2 },
  {
    id: 3,
    img: banner3,
  },
  { id: 4, img: banner4 },
  // { id: 5, img: banner5 },
];
export const PokerGames = [
  {
    image: "../assets/images/Aura/auraLobby.webp",
    title: "Lobby",
    gameCode: "98789",
    legacy: "",
  },

  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    legacy: "98789_2",
  },
  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
  },
  {
    image: "../assets/images/poker/5.webp",
    title: "Hi Low",
    gameCode: "56968",
    legacy: "56968_2",
  },
  {
    image: "../assets/images/poker/6.webp",
    title: "Worli Matka",
    gameCode: "92037",
    legacy: "92037_2",
  },
  {
    image: "../assets/images/poker/7.webp",
    title: "Poker",
    gameCode: "67564",
    legacy: "67564_2",
  },
  {
    image: "../assets/images/poker/8.webp",
    title: "32 Cards Casino",
    gameCode: "56967",
    legacy: "56967_2",
  },
  {
    image: "../assets/images/poker/9.webp",
    title: "Andhar Bahar",
    gameCode: "87564",
    legacy: "87564_2",
  },
  {
    image: "../assets/images/poker/10.webp",
    title: "Amar Akbar Anthony",
    gameCode: "98791",
    legacy: "98791_2",
  },
  {
    image: "../assets/images/poker/11.webp",
    title: "Race 20-20",
    gameCode: "90100",
    legacy: "90100_2",
  },
  {
    image: "../assets/images/poker/12.webp",
    title: "Bollywood Casino",
    gameCode: "67570",
    legacy: "67570_2",
  },
  {
    image: "../assets/images/poker/13.webp",
    title: "Six Player Poker",
    gameCode: "67565",
    legacy: "67565_2",
  },
  {
    image: "../assets/images/poker/14.webp",
    title: "Roulette",
    gameCode: "98788",
    legacy: "98788_2",
  },
  {
    image: "../assets/images/poker/15.webp",
    title: "Sic-bo",
    gameCode: "98566",
    legacy: "98566_2",
  },
  {
    image: "../assets/images/poker/16.webp",
    title: "Mufflis Teenpati",
    gameCode: "67601",
    legacy: "67601_3",
  },
  {
    image: "../assets/images/poker/17.webp",
    title: "Trio",
    gameCode: "67610",
    legacy: "67610_2",
  },
  {
    image: "../assets/images/poker/18.webp",
    title: "Baccarat",
    gameCode: "92038",
    legacy: "92038_2",
  },
  {
    image: "../assets/images/poker/19.webp",
    title: "Casino Meter",
    gameCode: "67575",
    legacy: "67575_2",
  },
  {
    image: "../assets/images/poker/20.webp",
    title: "Poker 20-20",
    gameCode: "67567",
    legacy: "67567_2",
  },
  {
    image: "../assets/images/poker/21.webp",
    title: "2 Card Teenpati",
    gameCode: "67660",
    legacy: "67660_2",
  },
  {
    image: "../assets/images/poker/22.webp",
    title: "Queen Race",
    gameCode: "67620",
    legacy: "67620_2",
  },
  {
    image: "../assets/images/poker/23.webp",
    title: "Teenpati Test",
    gameCode: "67630",
    legacy: "67630_2",
  },
  {
    image: "../assets/images/poker/24.webp",
    title: "Casino War",
    gameCode: "67580",
    legacy: "67580_2",
  },
  {
    image: "../assets/images/poker/25.webp",
    title: "The Trap",
    gameCode: "67680",
    legacy: "67680_2",
  },
  {
    image: "../assets/images/poker/26.webp",
    title: "Teenpati Open",
    gameCode: "67640",
    legacy: "67640_2",
  },
  {
    image: "../assets/images/poker/27.webp",
    title: "29 Card Baccarat",
    gameCode: "67690",
    legacy: "67690_2",
  },
  {
    image: "../assets/images/poker/28.webp",
    title: "Race to 17",
    gameCode: "67710",
    legacy: "67710_2",
  },
  {
    image: "../assets/images/poker/29.webp",
    title: "Roulette (Virtual)",
    gameCode: "98792",
    legacy: "98792_3",
  },
  {
    image: "../assets/images/poker/30.webp",
    title: "Dragon Tiger (Virtual)",
    gameCode: "98794",
    legacy: "98794_3",
  },
  {
    image: "../assets/images/poker/31.webp",
    title: "Amar Akbar Anthony (Virtual)",
    gameCode: "98795",
    legacy: "98795_3",
  },
  {
    image: "../assets/images/poker/32.webp",
    title: "Six Player Poker (Virtual)",
    gameCode: "67566",
    legacy: "67566_3",
  },
  {
    image: "../assets/images/poker/33.webp",
    title: "Poker (Virtual)",
    gameCode: "67563",
    legacy: "67563_3",
  },
  {
    image: "../assets/images/poker/34.webp",
    title: "32 Card Casino (Virtual)",
    gameCode: "56966",
    legacy: "56966_3",
  },
  {
    image: "../assets/images/poker/35.webp",
    title: "Teenpatti One-Day (Virtual)",
    gameCode: "56766",
    legacy: "56766_3",
  },
  {
    image: "../assets/images/poker/36.webp",
    title: "Andhar Bahar (Virtual)",
    gameCode: "87565",
    legacy: "87565_3",
  },
  {
    image: "../assets/images/poker/37.webp",
    title: "Teenpatti T20 (Virtual)",
    gameCode: "56769",
    legacy: "56769_3",
  },
  {
    image: "../assets/images/poker/38.webp",
    title: "Hi Low (Virtual)",
    gameCode: "56969",
    legacy: "56969_3",
  },
  {
    image: "../assets/images/poker/39.webp",
    title: "Matka (Virtual)",
    gameCode: "92036",
    legacy: "92036_3",
  },
  {
    image: "../assets/images/poker/40.webp",
    title: "7 Up & Down (Virtual)",
    gameCode: "98793",
    legacy: "98793_3",
  },
];
export const Aura = [
  { image: "../assets/images/Aura/auraLobby.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/2CardTeenPatti.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/20Poker.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/29Card.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/32CardCasino.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/amarAkbarAnthony.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/bollywoodCasino.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/matka.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/poker.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/roulette.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/teenPatti.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/teenPatti2.webp", gameCode: "77409" },

  { image: "../assets/images/AuraNew/6PlayerPoker.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/7UpandDown.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/7UpDown.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/andharBahar.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/baccarat.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/casinoMeter.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/casinoWar.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/dragonTiger.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/higherLower.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/higherLower2.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/muflisTeenPatti.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/queenRace.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/race.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/sicbo.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/teenPatti.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/teenPattiTest.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/theTrap.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/trio.webp", gameCode: "77409" },
];
export const EvolutionGames = [
  // { image: "../assets/images/poker/evoluation1.webp", title: "7 up & 7 down",gameCode:"21002" },
  // { image: "../assets/images/poker/evoluation2.webp", title: "7 up & 7 down",gameCode:"21300" },
  {
    image: "../assets/images/poker/evoluation3.webp",
    title: "",
    gameCode: "103518",
  },

  // {
  //   image: "../assets/images/poker/DICE-7.webp",
  //   title: "",
  //   gameCode: "21464",
  //   id: "RngSicbo00000001",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-19.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "frenchroulettene",
  // },
  // {
  //   image: "../assets/images/poker/PT-LIVE-017.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "casinoholdemne00",
  // },
  // {
  //   image: "../assets/images/poker/BACCARAT-7.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "baccarat2ne00000",
  // },
  // {
  //   image: "../assets/images/poker/EVOLUTION-LIVE-204.png",
  //   title: "",
  //   gameCode: "21296",
  //   id: "AndarBahar000001",
  // },
  // {
  //   image: "../assets/images/poker/BACCARAT-5.webp",
  //   title: "",
  //   gameCode: "21280",
  //   id: "rng-lbaccarat000",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-12.webp",
  //   title: "",
  //   gameCode: "21257",
  //   id: "SpeedAutoRo00001",
  // },
  // {
  //   image: "../assets/images/poker/GAME-SHOW-2.webp",
  //   title: "",
  //   gameCode: "21254",
  //   id: "CashOrCrash00001",
  // },
  // {
  //   image: "../assets/images/poker/GAME-SHOW-14.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "MegaBall00000001",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-11.webp",
  //   title: "",
  //   gameCode: "21190",
  //   id: "01rb77cq1gtenhmo",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-12.webp",
  //   title: "",
  //   gameCode: "21187",
  //   id: "lkcbrbdckjxajdol",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-18.webp",
  //   title: "",
  //   gameCode: "21027",
  //   id: "wzg6kdkad1oe7m5k",
  // },
  // {
  //   image: "../assets/images/poker/EVOLUTION-LIVE-009.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "n5emwq5c5dwepwam",
  // },
  // {
  //   image: "../assets/images/poker/GAME-SHOW-6.webp",
  //   title: "",
  //   gameCode: "21018",
  //   id: "LightningDice001",
  // },
  // {
  //   image: "../assets/images/poker/TRADINATIONAL-4.webp",
  //   title: "",
  //   gameCode: "21012",
  //   id: "DragonTiger00001",
  // },
  // {
  //   image: "../assets/images/poker/EVOLUTION-LIVE-015.webp",
  //   title: "",
  //   gameCode: "21010",
  //   id: "HoldemTable00001",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-16.webp",
  //   title: "",
  //   gameCode: "21002",
  //   id: "AmericanTable001",
  // },
  // {
  //   image: "../assets/images/poker/Ultimate-Texas-Hold-em.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "UTHTable00000001",
  // },
  // {
  //   image: "../assets/images/poker/Lightning-lotto.webp",
  //   title: "",
  //   gameCode: "21456",
  //   id: "Lightning6000001",
  // },
  // {
  //   image: "../assets/images/poker/King-of-Slot.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "kingofslots00000",
  // },
];
export const LiveGames = [
  { image: "../assets/images/poker/live1.webp", gameCode: "77409" },
  { image: "../assets/images/poker/live2.webp", gameCode: "5268" },
  {
    image: "../assets/images/poker/live3.webp",
    gameCode: "531914",
    message: "Comming Soon",
  },
  { image: "../assets/images/poker/live4.webp", gameCode: "523474" },
  {
    image: "../assets/images/poker/live5.webp",
    gameCode: "521401",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live6.webp",
    gameCode: "5115",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live7.webp",
    gameCode: "534714",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live8.webp",
    gameCode: "77409",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live9.webp",
    gameCode: "77409",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live10.webp",
    gameCode: "531914",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live11.webp",
    gameCode: "65068",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live12.webp",
    gameCode: "77409",
    message: "Comming Soon",
  },
  { image: "../assets/images/poker/live13.webp", gameCode: "531932" },
];
export const VivoGames = [
  // {
  //   image: "../assets/images/poker/vivoGamming.png",
  //   gameCode: "77257",
  //   size: "size",
  // },
  { image: "../assets/images/poker/vivo2.webp", gameCode: "75695" },
  { image: "../assets/images/poker/vivo3.webp", gameCode: "250416" },
  { image: "../assets/images/poker/vivo4.webp", gameCode: "250413" },
  { image: "../assets/images/poker/vivo5.webp", gameCode: "75695" },
  { image: "../assets/images/poker/vivo6.webp", gameCode: "82701" },
  { image: "../assets/images/poker/vivo7.webp", gameCode: "82690" },
  { image: "../assets/images/poker/vivo8.webp", gameCode: "82703" },
  { image: "../assets/images/poker/vivo9.webp", gameCode: "75694" },
  { image: "../assets/images/poker/vivo10.webp", gameCode: "75694" },
  { image: "../assets/images/poker/vivo11.webp", gameCode: "75694" },
  { image: "../assets/images/poker/vivo12.webp", gameCode: "75694" },
  { image: "../assets/images/poker/vivo13.webp", gameCode: "75694" },
];
export const BetGames = [
  {
    image: "../assets/images/poker/betGames.png",
    gameCode: "4001",
    size: "size",
  },
  { image: "../assets/images/poker/betGames1.webp", gameCode: "4005" },
  { image: "../assets/images/poker/betGames2.webp", gameCode: "4005" },
  { image: "../assets/images/poker/betGames3.webp", gameCode: "4004" },
  { image: "../assets/images/poker/betGames4.webp", gameCode: "4003" },
  { image: "../assets/images/poker/betGames5.webp", gameCode: "4002" },
  { image: "../assets/images/poker/betGames6.webp", gameCode: "4002" },
  { image: "../assets/images/poker/betGames7.webp", gameCode: "4001" },
  { image: "../assets/images/poker/betGames8.webp", gameCode: "4001" },
  { image: "../assets/images/poker/betGames9.webp", gameCode: "4001" },
  { image: "../assets/images/poker/betGames10.webp", gameCode: "4001" },
  { image: "../assets/images/poker/betGames11.webp", gameCode: "4007" },
  { image: "../assets/images/poker/betGames12.webp", gameCode: "4006" },
];
export const AisaGames = [
  {
    image: "../assets/images/poker/asiaGamming.png",
    gameCode: "77257",
    size: "contain",
  },
  // { image: "../assets/images/poker/betGames2.webp",gameCode:"4005"},
  // { image: "../assets/images/poker/betGames3.webp",gameCode:"4004"},
  // { image: "../assets/images/poker/betGames4.webp",gameCode:"4003"},
  // { image: "../assets/images/poker/betGames5.webp",gameCode:"4002"},
  // { image: "../assets/images/poker/betGames6.webp",gameCode:"4002"},
  // { image: "../assets/images/poker/betGames7.webp",gameCode:"4001"},
  // { image: "../assets/images/poker/betGames8.webp",gameCode:"4001"},
  // { image: "../assets/images/poker/betGames9.webp",gameCode:"4001"},
  // { image: "../assets/images/poker/betGames10.webp",gameCode:"4001"},
  // { image: "../assets/images/poker/betGames11.webp",gameCode:"4007"},
  // { image: "../assets/images/poker/betGames12.webp",gameCode:"4006"},
];

export const AviatorGames = [
  {
    image: "../assets/images/poker/avaitrix.jpg",
    gameCode: "9999",
    legacy: "67722-1_8",
  },
  {
    image: "../assets/images/poker/aviator1.webp",
    gameCode: "9999",
    legacy: "67722-2_8",
  },
  // { image: "../assets/images/poker/aviator2.webp", gameCode: "1190530" },
  // { image: "../assets/images/poker/aviator3.webp", gameCode: "1190535" },
  // { image: "../assets/images/poker/aviator4.webp", gameCode: "1190534" },
  // { image: "../assets/images/poker/aviator5.webp", gameCode: "1190532" },
  // { image: "../assets/images/poker/aviator6.webp", gameCode: "1190529" },
  // { image: "../assets/images/poker/aviator7.webp", gameCode: "1190528" },
  // { image: "../assets/images/poker/aviator8.webp", gameCode: "1190531" },
];
export const sidebarData = [
  {
    firstLevel: [
      // {id: 1,menu: "Upline Whatsapp Number",icon: <MdOutlineWhatsapp />,link: "/upline-whatsapp-number"},
      {
        id: 2,
        menu: "Balance Overview",
        icon: <FaWallet />,
        link: "/balance-overview",
      },
      {
        id: 3,
        menu: "Account Statement",
        icon: <MdOutlineAccountBalance />,
        link: "/account-statement",
      },
      {
        id: 4,
        menu: "Current Bets",
        icon: <BiWalletAlt />,
        link: "/current-bets",
      },
      {
        id: 5,
        menu: "Bets History",
        icon: <FaRegClock />,
        link: "/bets-history",
      },
      {
        id: 6,
        menu: "Profit & Loss",
        icon: <AiOutlineDollarCircle />,
        link: "/profit-and-loss",
      },
      { id: 7, menu: "Active Log", icon: <CgKeyhole />, link: "/active-log" },
      { id: 8, menu: "My Profile", icon: <FaRegUser />, link: "/my-profile" },
    ],
  },
  {
    secondLevel: [
      {
        // id: 8,
        menu: "Deposit",
        icon: <RiLuggageDepositLine />,
        link: "/deposit",
      },
      {
        id: 8,
        menu: "Deposit History",
        icon: <BiTransfer />,
        link: "/deposit-history",
      },
      {
        // id: 9,
        menu: "Withdraw",
        icon: <BiMoneyWithdraw />,
        link: "/withdraw",
      },
      {
        id: 9,
        menu: "Withdraw History",
        icon: <TbTransferIn />,
        link: "/withdraw-history",
      },
    ],
  },

  {
    thirdLevel: [
      { id: 10, menu: "Setting", icon: <IoMdSettings />, link: "/setting" },
      { id: 17, menu: "Customer Support", icon: <MdSupportAgent />, link: "/customer-support" },
    ],
  },
];

export const bottomNavigation = [
  {
    id: "3",
    menu: "In Play",
    icon: sportIcon,
    link: "/sports",
  },
  {
    id: "6",
    menu: "Sports",
    icon: leadgeIcon,
    link: "/sports",
  },
  {
    id: "1",
    menu: "Home",
    icon: HomeIcon,
    link: "/",
  },
  {
    id: "2",
    menu: "Casino",
    icon: casinoIcon,
    link: "/casino",
  },
  {
    id: "5",
    menu: "My Account",
    icon: betsIcon,
  },
];
export const popularArray = [
  {
    gameCode: 101313,
    image: "../assets/images/evolution-new.webp",
    id: "",
  },
  // {
  //   gameCode: 5269,
  //   image: "../assets/images/poker/unlimatedSicbo.webp",
  //   id: "224100",
  // },
  // {
  //   gameCode: 5252,
  //   image: "../assets/images/poker/DICE-7.webp",
  //   id: "224000",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/Grand-Wheel.webp",
  //   id: "266",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/GAME-SHOW-4.webp",
  //   id: "180",
  // },
  // {
  //   gameCode: 5114,
  //   image: "../assets/images/poker/TABLE-13.webp",
  //   id: "228002",
  // },

  // {
  //   gameCode: 5074,
  //   image: "../assets/images/poker/KM-TABLE-032.webp",
  //   id: "228000",
  // },
  // {
  //   gameCode: 5068,
  //   image: "../assets/images/poker/TRADINATIONAL-5.webp",
  //   id: "227100",
  // },
  // {
  //   gameCode: 5048,
  //   image: "../assets/images/poker/ROULETTE-12.webp",
  //   id: "221002",
  // },
  // {
  //   gameCode: 5046,
  //   image: "../assets/images/poker/PT-LIVE-010.webp",
  //   id: "5051",
  // },
  // {
  //   gameCode: 5035,
  //   image: "../assets/images/poker/TABLE-15.webp",
  //   id: "606000",
  // },
  // {
  //   gameCode: 5066,
  //   image: "../assets/images/poker/namasteRoullete2.jpg",
  //   id: "411000",
  // },
  // {
  //   gameCode: 5059,
  //   image: "../assets/images/poker/vipBlackjack.jpg",
  //   id: "225",
  // },
  // {
  //   gameCode: 5051,
  //   image: "../assets/images/poker/lucky7.webp",
  //   id: "228001",
  // },
  // {
  //   gameCode: 5050,
  //   image: "../assets/images/poker/diamondRoullete.jpg",
  //   id: "221003",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/devilNumber2.jpg",
  //   id: "257",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/Atlantis.jpg",
  //   id: "249",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/MegaDragon.jpg",
  //   id: "277",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/lucky-wizard.webp",
  //   id: "274",
  // },
  // {
  //   gameCode: 5266,
  //   image: "../assets/images/poker/VIPFortuneBaccarat.jpg",
  //   id: "106",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/BaccaratPro2.jpg",
  //   id: "41101",
  // },
  // {
  //   gameCode: 5271,
  //   image: "../assets/images/poker/pokerRoyal.webp",
  //   id: "537000",
  // },
  // {
  //   gameCode: 5270,
  //   image: "../assets/images/poker/cricketWar.webp",
  //   id: "45100",
  // },

  // {
  //   ProductId: 21000,
  //   image: "../assets/images/evolution.webp",
  // },
];

export const gamesLiveArray = [
  {
    img: "../assets/images/game-shows/GAME-SHOW-1.webp",
    name: "XXXtreme Lightning roulette",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-2.webp",
    name: "Cash or Crash",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "CashOrCrash00001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-3.webp",
    name: "Funky Time",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "FunkyTime0000001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-4.webp",
    name: "Dead or Alive Saloon",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "doasaloon0000001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-5.webp",
    name: "Crazy Time",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "CrazyTime0000001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-6.webp",
    name: "Lightning Dice",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "CrazyTime0000001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-7.webp",
    name: "Lightning Roulette",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "oviijmmqdaatpvon",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-8.webp",
    name: "Lightning Fever",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "oviijmmqdaatpvon",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-9.webp",
    name: "Extra Chilli",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "oviijmmqdaatpvon",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-10.webp",
    name: "Imperial Quest",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "ImperialQuest001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-11.webp",
    name: "Monopoly Live",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "Monopoly00000001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-12.webp",
    name: "Monopoly Big Baller",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "Monopoly00000001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-13.webp",
    name: "Crazy Coin Flip",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "CrazyCoinFlip001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-14.webp",
    name: "Mega Ball",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "RngMegaBall00001",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-15.webp",
    name: "Deal or Nodeal",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "dealnodeal000001",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-16.webp",
    name: "Wounderland",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "dealnodeal000001",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-17.webp",
    name: "Shweet Bonanza Candy Land",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "dealnodeal000001",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-18.webp",
    name: "Spin and Win Wild",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "RngMegaBall00001",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-19.webp",
    name: "Spin and Win Wild X2",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "MOWDream00000001",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-20.webp",
    name: "The Greatest Cards Show",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "n5emwq5c5dwepwam",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-21.webp",
    name: "Mega Wheel",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "RngMegaBall00001",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-22.webp",
    name: "Mega Fire Blaze Roulette",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "oviijmmqdaatpvon",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-23.webp",
    name: "Mega Roulette 500x",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "DoubleBallRou001",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-24.webp",
    name: "Mega Baccarat",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "leqhceumaq6qfoug",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-25.webp",
    name: "Quantum Auto Roulette",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "48z5pjps3ntvqc1b",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-26.webp",
    name: "Power Roulette",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "48z5pjps3ntvqc1b",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-27.webp",
    name: "Sicbo Delux",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-28.webp",
    name: "Mega Sicbo",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-29.webp",
    name: "Beton Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "DragonTiger00001",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-30.webp",
    name: "Hi-lo Club",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "DragonTiger00001",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-31.webp",
    name: "Quantum BlackJack Plus",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "LightningSbj0001",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-32.webp",
    name: "Cashback BlackJack",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "FreeBet000000001",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/Baccarat/BACCARAT-1.webp",
    name: "Golden Wealth Baccarat",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "gwbaccarat000001",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-2.webp",
    name: "Baccarat no commission",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "gwbaccarat000001",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-3.webp",
    name: "Speed Baccarat",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "leqhceumaq6qfoug",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-4.webp",
    name: "Speed Baccarat",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "leqhceumaq6qfoug",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-5.webp",
    name: "Lightning Baccarat First Person",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "LightningBac0001",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-6.webp",
    name: "Speed Baccarat",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "leqhceumaq6qfoug",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-7.webp",
    name: "Baccarat Classic",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-8.webp",
    name: "Baccarat Classic",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-9.webp",
    name: "Baccarat Classic",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-10.webp",
    name: "Baccarat Classic",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-11.webp",
    name: "Baccarat Classic",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-12.webp",
    name: "Baccarat Classic",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-13.webp",
    name: "Bet on Baccarat Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-005",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-14.webp",
    name: "Baccarat",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-001",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-15.webp",
    name: "Baccarat 3",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-003",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-16.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-013",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-17.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-18.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-19.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-20.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-21.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-22.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-23.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-24.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-013",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-25.webp",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-045",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-26.webp",
    name: "Speed Baccarat",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-002",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-27.webp",
    name: "Baccarat Insurance",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-003",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-28.webp",
    name: "Baccarat Insurance",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-003",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  // Baccarat End

  // Roulette Start
  {
    img: "../assets/images/Roulette/ROULETTE-1.webp",
    name: "Roulette Live",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "oviijmmqdaatpvon",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-2.webp",
    name: "Roulette Live",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "DoubleBallRou001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-3.webp",
    name: "Roulette Live",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-4.webp",
    name: "Roulette Live",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "DoubleBallRou001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-5.webp",
    name: "Instant Roulette",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "DoubleBallRou001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-6.webp",
    name: "Roulette First Person",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "oviijmmqdaatpvon",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-7.webp",
    name: "Amercian Roulette Live",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "oviijmmqdaatpvon",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-8.webp",
    name: "Roulette Live",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "oviijmmqdaatpvon",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-9.webp",
    name: "Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-032",
    cateogry: "SEXY",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-10.webp",
    name: "Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-032",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-11.webp",
    name: "Auto Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-061",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-12.webp",
    name: "Speed Auto Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-058",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-13.webp",
    name: "Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-032",
    cateogry: "VENUS",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-14.webp",
    name: "Speed Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-062",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-15.webp",
    name: "Azure Roulette",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "oviijmmqdaatpvon",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-16.webp",
    name: "American Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-021",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-17.webp",
    name: "Deutsches Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-022",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-18.webp",
    name: "Roulette Live",
    // platForm: "PT",
    // gameType: "LIVE",
    // casinoType: "PT-LIVE-023",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-19.webp",
    name: "Football French Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-023",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-20.webp",
    name: "Football Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-024",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-21.webp",
    name: "Spread Bets Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-027",
    cateogry: "PT",
    type: "ROULETTE",
  },

  {
    img: "../assets/images/Dice/DICE-1.webp",
    name: "Bac Bo Live",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "BacBo00000000001",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-2.webp",
    name: "Super Sic Bo",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "SuperSicBo000001",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-3.webp",
    name: "Craps Live",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "Craps00000000001",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-4.webp",
    name: "Experor Sic Bo",
    // platForm: "1006",
    // gameType: "1",
    // casinoType: "LIVE CASINO",
    // gameid: "SuperSicBo000001",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-5.webp",
    name: "Extra Sic bo",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
    cateogry: "SEXY",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-6.webp",
    name: "Red Blue Duel",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-010",
    cateogry: "SEXY",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-7.webp",
    name: "Sic Bo",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-007",
    cateogry: "VENUS",
    type: "DICE",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-1.webp",
    name: "Super Andhar Bahar",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-2.webp",
    name: "Fan Tan",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-3.webp",
    name: "Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-4.webp",
    name: "Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-5.webp",
    name: "Teen Patti",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-6.webp",
    name: "Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-7.webp",
    name: "Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-8.webp",
    name: "Teen Patti",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-9.webp",
    name: "Extra Teen Patti",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-10.webp",
    name: "Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-11.webp",
    name: "Andhar Bahar",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-12.webp",
    name: "Teen Patti",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "PT",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-13.webp",
    name: "Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "PP",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-14.webp",
    name: "Andhar Bahar",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-15.webp",
    name: "Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "VENUS",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-16.webp",
    name: "Dragon Tiger",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "VENUS",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-181.webp",
    name: "Lightning Black Jack",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-208.webp",
    name: "Lightning Black Jack First Person",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-002.webp",
    name: "Black Jack A",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-015.webp",
    name: "Casino Hold'em",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-009.webp",
    name: "Triple Card Poker",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-012.webp",
    name: "Infinite Black Jack",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-024.webp",
    name: "Caribbean Stud Poker",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "SuperSicBo000001",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-006.webp",
    name: "Alexandrite Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-006",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-007.webp",
    name: "Allbets Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-007",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-040.webp",
    name: "One Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-040",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-041.webp",
    name: "ONE Blackjack 2 - Ruby",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-041",
    cateogry: "PP",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/PT-LIVE-008.webp",
    name: "Majority Rules Speed Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-008",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-010.webp",
    name: "Unlimited Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-010",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/PT-LIVE-016.webp",
    name: "3 Card Brag",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-016",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-017.webp",
    name: "Casino Hold AEM",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-017",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-019.webp",
    name: "Bet on Poker Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-019",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  // Table Start KINGMAKER (casino Done) -------
  {
    img: "../assets/images/Table/TABLE-2.webp",
    name: "Cash Rocket",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-041",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-3.webp",
    name: "Ludo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-060",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-4.webp",
    name: "7 Up 7 Down",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-028",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-5.webp",
    name: "sicbo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-015",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-6.webp",
    name: "Coin Toss",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-036",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/TABLE-7.webp",
    name: "Cards Hi Lo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-037",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-8.webp",
    name: "5 Card Poker",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-049",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-9.webp",
    name: "Card Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-022",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/TABLE-12.webp",
    name: "Bonus Dice",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-043",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-13.webp",
    name: "32 Cards",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-039",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-14.webp",
    name: "Plinko",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-040",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-15.webp",
    name: "keno",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-018",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-16.webp",
    name: "Colour Game",
    name: "Colour Game",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-050",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-045.webp",
    name: "Monkey King Roulette",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-045",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-048.webp",
    name: "Horse Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-048",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-053.webp",
    name: "Marble Knockout",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-053",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-055.webp",
    name: "KM Virtual Greyhound Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-055",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-058.webp",
    name: "KM Virtual Treadmill Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-058",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  // Table End KINGMAKER (casino Done) -------

  // Table Start JILI (casino Done) -------
  {
    img: "../assets/images/JILI/JILI-TABLE-002.webp",
    name: "AK47",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-002",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-003.webp",
    name: "Andar Bahar",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-003",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-004.webp",
    name: "Rummy",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-004",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-005.webp",
    name: "NumberKing",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-005",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-006.webp",
    name: "Poker King",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-006",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-007.webp",
    name: "Big small",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-007",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/JILI/JILI-TABLE-008.webp",
    name: "iRich Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-008",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-009.webp",
    name: "Callbreak",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-009",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-010.webp",
    name: "TeenPatti Joker",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-010",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-011.webp",
    name: "7up7down",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-011",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-012.webp",
    name: "Dragon & Tiger",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-012",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-013.webp",
    name: "Callbreak Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-013",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-014.webp",
    name: "Baccarat",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-014",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-015.webp",
    name: "Fortune Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-015",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-016.webp",
    name: "TeenPatti 20-20",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/JILI/JILI-TABLE-017.webp",
    name: "Sic Bo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-017",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-018.webp",
    name: "Super Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-018",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-021.webp",
    name: "Ludo Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-021",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-027.webp",
    name: "Golden Land",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-027",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-028.webp",
    name: "PAPPU",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-028",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-030.webp",
    name: "Go Rush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-030",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-031.webp",
    name: "Tower",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-031",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-032.webp",
    name: "HILO",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-032",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-033.webp",
    name: "Mines",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-033",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-035.webp",
    name: "Limbo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-035",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-036.webp",
    name: "wheel",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-036",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-039.webp",
    name: "Color Prediction",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-039",
    cateogry: "JILI",
    type: "TABLE",
  },
  // Table End JILI (casino Done) -------

  // Slot Start PP (casino Done) -----
  {
    img: "../assets/images/slot/PP-SLOT-002.webp",
    name: "Gates of Olympus",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-082.webp",
    name: "Sweet Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-189.webp",
    name: "Starlight Princess",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-189",
    cateogry: "PP",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-183.webp",
    name: "5 Lions Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-183",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-080.webp",
    name: "5 Lions Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-080",
    cateogry: "PP",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-049.webp",
    name: "Great Rhino Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-049",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-069.webp",
    name: "Fire Strike",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-069",
    cateogry: "PP",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-067.webp",
    name: "Sweet Bonanza Xmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-067",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-015.webp",
    name: "Bonanza Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-015",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-176.webp",
    name: "Buffalo King Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-176",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-181.webp",
    name: "The Magic Cauldron - Enchanted Brew",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-181",
    cateogry: "PP",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-046.webp",
    name: "Fruit Party",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-046",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-187.webp",
    name: "Pyramid Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-187",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-204.webp",
    name: "Candy Village",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-204",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-126.webp",
    name: "3 Kingdoms - Battle of Red Cliffs",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-126",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-104.webp",
    name: "5 Lions",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-104",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-072.webp",
    name: "John Hunter and the Tomb of the Scarab Queen",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-072",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-107.webp",
    name: "Joker's Jewels",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-107",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-025.webp",
    name: "Gems Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-025",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-210.webp",
    name: "Santa's Wonderland",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-210",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-233.webp",
    name: "Wild West Gold Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-233",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-234.webp",
    name: "Spirit of Adventure",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-234",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-235.webp",
    name: "Fire Strike 2",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-235",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-236.webp",
    name: "Barn Festival",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-236",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-237.webp",
    name: "Chicken Chase",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-237",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-238.webp",
    name: "Goblin Heist Powernudge",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-238",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-239.webp",
    name: "Eye of Cleopatra",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-239",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-240.webp",
    name: "The Great Stick-Up",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-240",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-296.webp",
    name: "Aztec Blaze",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-296",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-297.webp",
    name: "Starlight Christmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-297",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-298.webp",
    name: "Towering Fortunes",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-298",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-299.webp",
    name: "Firebird Spirit",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-299",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-300.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-300",
    cateogry: "PP",
    type: "SLOT",
  },
  // Slot End PP (casino Done) -----

  // Slot Start KINGMAKER (casino Done) -----
  {
    img: "../assets/images/slot/KM-SLOT-001.webp",
    name: "Sugar Blast",
    platForm: "KINGMAKER",
    gameType: "SLOT",
    casinoType: "KM-SLOT-001",
    cateogry: "KINGMAKER",
    type: "SLOT",
  },
  // Slot End KINGMAKER (casino Done) -----

  // Slot Start DRAGOONSOFT (casino Done) -----
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-003.webp",
    name: "777",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-003",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-004.webp",
    name: "Get Money",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-004",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-012.webp",
    name: "Over Dragon's Gate",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-012",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-002.webp",
    name: "Phoenix",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-002",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-011.webp",
    name: "Diamond Mogul",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-011",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-005.webp",
    name: "Rich Now",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-005",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-008.webp",
    name: "Dracula",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-008",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-020.webp",
    name: "Bust Treasury",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-020",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-001.webp",
    name: "Coin Cat",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-001",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-015.webp",
    name: "Egypt Oracle",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-015",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  // Slot End DRAGOONSOFT (casino Done) -----

  // Slot Start SPADE (casino Done) -----
  {
    img: "../assets/images/slot/SG-SLOT-106.webp",
    name: "Fiery Sevens Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-106",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-074.webp",
    name: "Mega7",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-074",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-116.webp",
    name: "Legacy Of Kong Maxways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-116",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-069.webp",
    name: "888",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-069",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-112.webp",
    name: "Fruits Mania",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-112",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-076.webp",
    name: "DoubleFlame",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-076",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-110.webp",
    name: "Space Conquest",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-110",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-081.webp",
    name: "Triple Panda",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-081",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-077.webp",
    name: "Book of Myth",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-077",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-095.webp",
    name: "Poker Ways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-095",
    cateogry: "SPADE",
    type: "SLOT",
  },
  // Slot End SPADE (casino Done) -----

  // Slot Start PT (casino Done) -----
  {
    img: "../assets/images/slot/PT-SLOT-082.webp",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-082",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-001.webp",
    name: "Triple Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-001",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-005.webp",
    name: "Long Long Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-005",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-006.webp",
    name: "Great Blue",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-006",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-009.webp",
    name: "Golden Tour",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-009",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-012.webp",
    name: "Zhao Cai Jin Bao",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-012",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-022.webp",
    name: "Ni Shu Shen Me",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-022",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-023.webp",
    name: "Chaoji 888",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-023",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-031.webp",
    name: "Lie Yan Zuan Shi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-031",
    cateogry: "PT",
    type: "SLOT",
  },
  // Slot End PT (casino Done) -----

  // Slot Start JDB (casino Done) -----
  {
    img: "../assets/images/slot/JDB-SLOT-020.webp",
    name: "Olympian Temple",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-020",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-064.webp",
    name: "Poker King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-064",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-065.webp",
    name: "Lucky Racing",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-065",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-066.webp",
    name: "Birds Party",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-066",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-067.webp",
    name: "Go Lai Fu",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-067",
    cateogry: "JDB",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/JDB-SLOT-070.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-070",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-074.webp",
    name: "Mjoinir",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-074",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-075.webp",
    name: "Treasure Bowl",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-075",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-076.webp",
    name: "Funky King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-076",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-077.webp",
    name: "Super Niubi Deluxe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-077",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-079.webp",
    name: "Lucky Fuwa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-079",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-084.webp",
    name: "Golden Disco",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-084",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-093.webp",
    name: "Lucky Diamond",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-093",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-094.webp",
    name: "Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-094",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-095.webp",
    name: "NinjaX",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-095",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-098.webp",
    name: "MarvelousIV",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-098",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-099.webp",
    name: "Maya Gold Crazy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-099",
    cateogry: "JDB",
    type: "SLOT",
  },
  // Slot End JDB (casino Done) -----

  // Slot Start FC (casino Done) -----
  {
    img: "../assets/images/slot/FC-SLOT-001.webp",
    name: "GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-001",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-002.webp",
    name: "THREE LITTLE PIGS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-002",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-003.webp",
    name: "HOT POT PARTY",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-003",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-005.webp",
    name: "PONG PONG HU",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-005",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-009.webp",
    name: "HAPPY DUO BAO",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-009",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-012.webp",
    name: "LUXURY GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-012",
    cateogry: "FC",
    type: "SLOT",
  },
  // Slot End FC (casino Done) -----

  // Slot Start JILI (casino Done) -----
  {
    img: "../assets/images/slot/JILI-SLOT-002.webp",
    name: "Hot Chilli",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-002",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-004.webp",
    name: "War Of Dragons",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-004",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-005.webp",
    name: "Fortune Tree",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-005",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-006.webp",
    name: "Lucky Ball",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-006",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-007.webp",
    name: "Hyper Burst",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-007",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-008.webp",
    name: "Shanghai Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-008",
    cateogry: "JILI",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/JILI-SLOT-009.webp",
    name: "Fa Fa Fa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-009",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-013.webp",
    name: "SevenSevenSeven",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-013",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-014.webp",
    name: "Crazy777",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-014",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-015.webp",
    name: "Bubble Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-015",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-017.webp",
    name: "Crazy FaFaFa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-017",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-020.webp",
    name: "Candy Baby",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-020",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-021.webp",
    name: "Diamond Party",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-021",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-022.webp",
    name: "Fengshen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-022",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-023.webp",
    name: "Golden Bank",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-023",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-024.webp",
    name: "Lucky Goldbricks",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-024",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-026.webp",
    name: "Charge Buffalo",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-026",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-027.webp",
    name: "Super Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-027",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-028.webp",
    name: "Jungle King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-028",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-029.webp",
    name: "Money Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-029",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-030.webp",
    name: "Golden Queen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-030",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-038.webp",
    name: "Pharaoh Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-038",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-043.webp",
    name: "Fortune Gems",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-043",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-044.webp",
    name: "Party Night",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-044",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-045.webp",
    name: "Crazy Hunter",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-045",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-046.webp",
    name: "Magic Lamp",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-046",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-047.webp",
    name: "TWINWINS",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-047",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-051.webp",
    name: "Mega Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-051",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-063.webp",
    name: "World Cup",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-063",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-071.webp",
    name: "Neko Fortune",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-071",
    cateogry: "JILI",
    type: "SLOT",
  },
  // Slot End JILI (casino Done) -----

  // Fishing Start SPADE (casino Done) -----
  {
    img: "../assets/images/Fishing/SG-FISH-001.webp",
    name: "Fishing God",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-001",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-002.webp",
    name: "Fishing War",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-002",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-003.webp",
    name: "Alien Hunter",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-003",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-004.webp",
    name: "Zombie Party",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-004",
    cateogry: "SPADE",
    type: "FISHING",
  },
  // Fishing End SPADE (casino Done) -----

  // Fishing Start JILI (casino Done) -----
  {
    img: "../assets/images/Fishing/JILI-FISH-001.webp",
    name: "Royal Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-001",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-002.webp",
    name: "Bombing Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-002",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-004.webp",
    name: "Dinosaur Tycoon",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-004",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-005.webp",
    name: "Happy Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-005",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-006.webp",
    name: "Dragon Fortune",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-006",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-007.webp",
    name: "Mega Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-007",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-008.webp",
    name: "Boom Legend",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-008",
    cateogry: "JILI",
    type: "FISHING",
  },
  // Fishing End JILI (casino Done) -----

  // Egame Start JDB (casino Done) -----
  {
    img: "../assets/images/Egame/JDB-EGAME-001.webp",
    name: "Crazy King Kong",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-001",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-002.webp",
    name: "Lucky Color Game",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-002",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-006.webp",
    name: "King Of Football",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-006",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-007.webp",
    name: "Super Super Fruit",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-007",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-010.webp",
    name: "Beer Tycoon",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-010",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-011.webp",
    name: "Jogo Do Bicho",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-011",
    cateogry: "JDB",
    type: "EGAME",
  },
  // Egame End JDB (casino Done) -----

  // Egame Start PP (casino Done) -----
  {
    img: "../assets/images/Egame/PP-EGAME-002.webp",
    name: "Penalty Shootout",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-002",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-003.webp",
    name: "Horse Racing",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-003",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-004.webp",
    name: "Fantastic League",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-004",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-005.webp",
    name: "Greyhound Racing",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-005",
    cateogry: "PP",
    type: "EGAME",
  },

  {
    img: "../assets/images/Egame/PP-EGAME-006.webp",
    name: "Force 1",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-006",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-007.webp",
    name: "Darts",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-007",
    cateogry: "PP",
    type: "EGAME",
  },
  // Egame End PP (casino Done) -----
];

export const gamesTabelArray = [
  {
    img: "../assets/images/Table/TABLE-2.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-3.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/KM-TABLE-053.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-5.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-6.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-7.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },

  {
    img: "../assets/images/Table/TABLE-8.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-9.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-10.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-11.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-12.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },

  {
    img: "../assets/images/Table/TABLE-13.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-14.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-15.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-16.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/KM-TABLE-058.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },

  {
    img: "../assets/images/Table/KM-TABLE-055.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/KM-TABLE-048.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/KM-TABLE-045.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
];

export const gamesSelectionArray = [
  {
    id: 1,
    title: "Game Shows",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/game-shows/GAME-SHOW-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "XxxtremeLigh0001",
      },
      {
        id: 2,
        img: "../assets/images/game-shows/GAME-SHOW-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CashOrCrash00001",
      },
      {
        id: 3,
        img: "../assets/images/game-shows/GAME-SHOW-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FunkyTime0000001",
      },
      {
        id: 4,
        img: "../assets/images/game-shows/GAME-SHOW-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "doasaloon0000001",
      },
      {
        id: 5,
        img: "../assets/images/game-shows/GAME-SHOW-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },
      {
        id: 6,
        img: "../assets/images/game-shows/GAME-SHOW-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },

      {
        id: 7,
        img: "../assets/images/game-shows/GAME-SHOW-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/game-shows/GAME-SHOW-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/game-shows/GAME-SHOW-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 10,
        img: "../assets/images/game-shows/GAME-SHOW-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "ImperialQuest001",
      },
      {
        id: 11,
        img: "../assets/images/game-shows/GAME-SHOW-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 12,
        img: "../assets/images/game-shows/GAME-SHOW-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 13,
        img: "../assets/images/game-shows/GAME-SHOW-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyCoinFlip001",
      },
      {
        id: 14,
        img: "../assets/images/game-shows/GAME-SHOW-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 15,
        img: "../assets/images/game-shows/GAME-SHOW-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 16,
        img: "../assets/images/game-shows/GAME-SHOW-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 17,
        img: "../assets/images/game-shows/GAME-SHOW-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 18,
        img: "../assets/images/game-shows/GAME-SHOW-18.webp",
        id: 17,
        img: "../assets/images/game-shows/GAME-SHOW-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 19,
        img: "../assets/images/game-shows/GAME-SHOW-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "MOWDream00000001",
      },

      {
        id: 20,
        img: "../assets/images/game-shows/GAME-SHOW-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "n5emwq5c5dwepwam",
      },

      {
        id: 21,
        img: "../assets/images/game-shows/GAME-SHOW-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 22,
        img: "../assets/images/game-shows/GAME-SHOW-22.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 23,
        img: "../assets/images/game-shows/GAME-SHOW-23.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DoubleBallRou001",
      },
      {
        id: 24,
        img: "../assets/images/game-shows/GAME-SHOW-24.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 25,
        img: "../assets/images/game-shows/GAME-SHOW-25.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 26,
        img: "../assets/images/game-shows/GAME-SHOW-26.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },

      {
        id: 27,
        img: "../assets/images/game-shows/GAME-SHOW-27.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 28,
        img: "../assets/images/game-shows/GAME-SHOW-28.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 29,
        img: "../assets/images/game-shows/GAME-SHOW-29.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DragonTiger00001",
      },
      {
        id: 30,
        img: "../assets/images/game-shows/GAME-SHOW-30.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DragonTiger00001",
      },
      {
        id: 31,
        img: "../assets/images/game-shows/GAME-SHOW-31.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "LightningSbj0001",
      },
      {
        id: 32,
        img: "../assets/images/game-shows/GAME-SHOW-32.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FreeBet000000001",
      },
    ],
  },
  {
    id: 2,
    title: "Baccarat",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/Baccarat/BACCARAT-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
      {
        id: 2,
        img: "../assets/images/Baccarat/BACCARAT-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
      {
        id: 3,
        img: "../assets/images/Baccarat/BACCARAT-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 4,
        img: "../assets/images/Baccarat/BACCARAT-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 5,
        img: "../assets/images/Baccarat/BACCARAT-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "LightningBac0001",
      },
      {
        id: 6,
        img: "../assets/images/Baccarat/BACCARAT-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 7,
        img: "../assets/images/Baccarat/BACCARAT-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 8,
        img: "../assets/images/Baccarat/BACCARAT-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 9,
        img: "../assets/images/Baccarat/BACCARAT-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 10,
        img: "../assets/images/Baccarat/BACCARAT-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 11,
        img: "../assets/images/Baccarat/BACCARAT-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 12,
        img: "../assets/images/Baccarat/BACCARAT-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 13,
        img: "../assets/images/Baccarat/BACCARAT-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 14,
        img: "../assets/images/Baccarat/BACCARAT-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "LightningBac0001",
      },
      {
        id: 15,
        img: "../assets/images/Baccarat/BACCARAT-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 16,
        img: "../assets/images/Baccarat/BACCARAT-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 17,
        img: "../assets/images/Baccarat/BACCARAT-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 18,
        img: "../assets/images/Baccarat/BACCARAT-18.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
      {
        id: 19,
        img: "../assets/images/Baccarat/BACCARAT-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 20,
        img: "../assets/images/Baccarat/BACCARAT-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 21,
        img: "../assets/images/Baccarat/BACCARAT-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 22,
        img: "../assets/images/Baccarat/BACCARAT-22.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 23,
        img: "../assets/images/Baccarat/BACCARAT-23.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 24,
        img: "../assets/images/Baccarat/BACCARAT-24.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 25,
        img: "../assets/images/Baccarat/BACCARAT-25.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 26,
        img: "../assets/images/Baccarat/BACCARAT-26.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 27,
        img: "../assets/images/Baccarat/BACCARAT-27.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
      {
        id: 28,
        img: "../assets/images/Baccarat/BACCARAT-28.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
    ],
  },
  {
    id: 3,
    title: "Roulette",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/Roulette/ROULETTE-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 2,
        img: "../assets/images/Roulette/ROULETTE-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DoubleBallRou001",
      },
      {
        id: 3,
        img: "../assets/images/Roulette/ROULETTE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "XxxtremeLigh0001",
      },
      {
        id: 4,
        img: "../assets/images/Roulette/ROULETTE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DoubleBallRou001",
      },
      {
        id: 5,
        img: "../assets/images/Roulette/ROULETTE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DoubleBallRou001",
      },
      {
        id: 6,
        img: "../assets/images/Roulette/ROULETTE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 7,
        img: "../assets/images/Roulette/ROULETTE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/Roulette/ROULETTE-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/Roulette/ROULETTE-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 10,
        img: "../assets/images/Roulette/ROULETTE-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 11,
        img: "../assets/images/Roulette/ROULETTE-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 12,
        img: "../assets/images/Roulette/ROULETTE-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 13,
        img: "../assets/images/Roulette/ROULETTE-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 14,
        img: "../assets/images/Roulette/ROULETTE-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 15,
        img: "../assets/images/Roulette/ROULETTE-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 16,
        img: "../assets/images/Roulette/ROULETTE-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 17,
        img: "../assets/images/Roulette/ROULETTE-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 18,
        img: "../assets/images/Roulette/ROULETTE-18.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 19,
        img: "../assets/images/Roulette/ROULETTE-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 20,
        img: "../assets/images/Roulette/ROULETTE-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 21,
        img: "../assets/images/Roulette/ROULETTE-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
    ],
  },
  {
    id: 4,
    title: "Dice",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/Dice/DICE-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "BacBo00000000001",
      },
      {
        id: 2,
        img: "../assets/images/Dice/DICE-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 3,
        img: "../assets/images/Dice/DICE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Craps00000000001",
      },
      {
        id: 4,
        img: "../assets/images/Dice/DICE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 5,
        img: "../assets/images/Dice/DICE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 6,
        img: "../assets/images/Dice/DICE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 7,
        img: "../assets/images/Dice/DICE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
    ],
  },
  {
    id: 5,
    title: "Traditional Games",
    gamesCard: [
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },

      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "SEXY",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "SEXY",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "SEXY",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "SEXY",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "TRADITIONAL",
      },

      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PP",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PP",
        type: "TRADITIONAL",
      },

      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "VENUS",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "VENUS",
        type: "TRADITIONAL",
      },
    ],
  },
  {
    id: 5,
    title: "Card Games",
    gamesCard: [
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-181.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-208.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },

      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-002.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-015.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-009.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },

      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-012.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-024.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-006.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-007.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PP-LIVE-040.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PP",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PP-LIVE-041.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PP",
        type: "GAME-CARD",
      },

      {
        img: "../assets/images/CardGames/PT-LIVE-008.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-010.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },

      {
        img: "../assets/images/CardGames/PT-LIVE-016.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-017.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-019.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
    ],
  },
];

export const tableSelectionArray = [
  {
    id: 1,
    title: "",
    gamesCard: [
      // {
      //   id: 1,
      //   img: "../assets/images/Table/TABLE-1.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "XxxtremeLigh0001",
      // },
      {
        id: 2,
        img: "../assets/images/Table/TABLE-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CashOrCrash00001",
      },
      {
        id: 3,
        img: "../assets/images/Table/TABLE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FunkyTime0000001",
      },
      {
        id: 4,
        img: "../assets/images/Table/TABLE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "doasaloon0000001",
      },
      {
        id: 5,
        img: "../assets/images/Table/TABLE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },
      {
        id: 6,
        img: "../assets/images/Table/TABLE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },

      {
        id: 7,
        img: "../assets/images/Table/TABLE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/Table/TABLE-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/Table/TABLE-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      // {
      //   id: 10,
      //   img: "../assets/images/Table/TABLE-10.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "ImperialQuest001",
      // },
      // {
      //   id: 11,
      //   img: "../assets/images/Table/TABLE-11.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "Monopoly00000001",
      // },
      {
        id: 12,
        img: "../assets/images/Table/TABLE-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 13,
        img: "../assets/images/Table/TABLE-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyCoinFlip001",
      },
      {
        id: 14,
        img: "../assets/images/Table/TABLE-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 15,
        img: "../assets/images/Table/TABLE-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 16,
        img: "../assets/images/Table/TABLE-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      // {
      //   id: 17,
      //   img: "../assets/images/Table/TABLE-17.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "dealnodeal000001",
      // },
      // {
      //   id: 18,
      //   img: "../assets/images/Table/TABLE-18.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "RngMegaBall00001",
      // },
      // {
      //   id: 19,
      //   img: "../assets/images/Table/TABLE-19.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "MOWDream00000001",
      // },

      // {
      //   id: 20,
      //   img: "../assets/images/Table/TABLE-20.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "n5emwq5c5dwepwam",
      // },

      // {
      //   id: 21,
      //   img: "../assets/images/Table/TABLE-21.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "RngMegaBall00001",
      // },
    ],
  },
];

export const slotSelectionArray = [
  {
    id: 1,
    title: "",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/slot/KM-SLOT-001.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "XxxtremeLigh0001",
      },
      {
        id: 2,
        img: "../assets/images/slot/KM-SLOT-001.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CashOrCrash00001",
      },
      {
        id: 3,
        img: "../assets/images/Table/TABLE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FunkyTime0000001",
      },
      {
        id: 4,
        img: "../assets/images/Table/TABLE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "doasaloon0000001",
      },
      {
        id: 5,
        img: "../assets/images/Table/TABLE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },
      {
        id: 6,
        img: "../assets/images/Table/TABLE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },

      {
        id: 7,
        img: "../assets/images/Table/TABLE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/Table/TABLE-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/Table/TABLE-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 10,
        img: "../assets/images/Table/TABLE-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "ImperialQuest001",
      },
      {
        id: 11,
        img: "../assets/images/Table/TABLE-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 12,
        img: "../assets/images/Table/TABLE-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 13,
        img: "../assets/images/Table/TABLE-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyCoinFlip001",
      },
      {
        id: 14,
        img: "../assets/images/Table/TABLE-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 15,
        img: "../assets/images/Table/TABLE-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 16,
        img: "../assets/images/Table/TABLE-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 17,
        img: "../assets/images/Table/TABLE-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 18,
        img: "../assets/images/Table/TABLE-18.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 19,
        img: "../assets/images/Table/TABLE-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "MOWDream00000001",
      },

      {
        id: 20,
        img: "../assets/images/Table/TABLE-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "n5emwq5c5dwepwam",
      },

      {
        id: 21,
        img: "../assets/images/Table/TABLE-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
    ],
  },
];

export const fishingSelectionArray = [
  {
    id: 1,
    title: "",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/slot/KM-SLOT-001.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "XxxtremeLigh0001",
      },
      {
        id: 2,
        img: "../assets/images/slot/KM-SLOT-001.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CashOrCrash00001",
      },
      {
        id: 3,
        img: "../assets/images/Table/TABLE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FunkyTime0000001",
      },
      {
        id: 4,
        img: "../assets/images/Table/TABLE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "doasaloon0000001",
      },
      {
        id: 5,
        img: "../assets/images/Table/TABLE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },
      {
        id: 6,
        img: "../assets/images/Table/TABLE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },

      {
        id: 7,
        img: "../assets/images/Table/TABLE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/Table/TABLE-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/Table/TABLE-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 10,
        img: "../assets/images/Table/TABLE-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "ImperialQuest001",
      },
      {
        id: 11,
        img: "../assets/images/Table/TABLE-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 12,
        img: "../assets/images/Table/TABLE-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 13,
        img: "../assets/images/Table/TABLE-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyCoinFlip001",
      },
      {
        id: 14,
        img: "../assets/images/Table/TABLE-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 15,
        img: "../assets/images/Table/TABLE-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 16,
        img: "../assets/images/Table/TABLE-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 17,
        img: "../assets/images/Table/TABLE-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 18,
        img: "../assets/images/Table/TABLE-18.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 19,
        img: "../assets/images/Table/TABLE-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "MOWDream00000001",
      },

      {
        id: 20,
        img: "../assets/images/Table/TABLE-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "n5emwq5c5dwepwam",
      },

      {
        id: 21,
        img: "../assets/images/Table/TABLE-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
    ],
  },
];

export const showMenuUsingPath = [{ path: "/change-password" }, { path: "" }];

export const casinoimg = [
  { id: 1, img: c1 },
  { id: 2, img: c2 },
  { id: 3, img: c3 },
  { id: 4, img: c4 },
  { id: 5, img: c5 },
  { id: 6, img: c6 },
  { id: 7, img: c7 },
  { id: 8, img: c8 },
  { id: 9, img: c9 },
  { id: 10, img: c10 },
  { id: 11, img: c11 },
  { id: 12, img: c12 },
];
export const NewLiveCasino = [
  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },
  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },

  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },
  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },
  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },
];
